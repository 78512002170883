* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}

*::-webkit-scrollbar {
  display: none;
}

.transition-marker {
  -webkit-transition: 2.5s all linear !important;
  transition: 2.5s all linear !important;
}

.table-container {
  position: absolute;
  top: 10vh;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  left: 0;
}

.table-devices {
  opacity: 0.9;
  max-height: 450px;
  display: block;
  overflow: scroll;
  border-collapse: separate;
  border: 0 solid transparent;
  border-spacing: 0;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}

.table-devices tr:nth-child(1) {
  background: transparent !important;
  background-image: url("../../assets//images/table-top-bar.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: right !important;
  top: calc(10vh - 39px);
  color: white;
  text-transform: uppercase;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
}

.table-devices tr:nth-child(1) th {
  font-size: 15px;
  height: 40px;
  max-width: calc(800px / 10);
  width: calc(800px / 10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
}

.table-devices tr td {
  color: white;
  max-width: calc(800px / 10);
  width: calc(800px / 10);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.table-devices::-webkit-scrollbar {
  display: none;
}

.devices-col {
  font-size: 16px;
  font-family: "Montserrat";
  text-align: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: center;
}

.devices-col-no {
  font-size: 20px;
  text-align: center;
  font-family: "Montserrat";
}

.devices-col-btn {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.follow-button {
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  margin: 10px 0;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  background-image: url("../../assets/images/toggles/follow-off.svg");
  background-color: transparent;
  border: 0 transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* Safari 10.1+ (alternate method) */
}

.follow-button:hover {
  cursor: pointer;
}

.info-button {
  height: 25px;
  width: 100%;
  margin: 10px 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  background-image: url("../../assets/images/team_info/info_button_normal.png");
  background-color: transparent;
  border: 0 transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.info-button:hover {
  cursor: pointer;
  background-image: url("../../assets/images/team_info/info_button_pushed.png");
}

#on {
  background-image: url("../../assets//images/toggles/follow-on.svg");
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.buttons-container {
  position: absolute;
  right: 10px;
  top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  row-gap: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.satelliteButton {
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 0 transparent;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.satelliteButton:hover {
  cursor: pointer;
}

.table-devices tr:nth-child(even) {
  background: #636262;
  background-image: url("../../assets/images//phone_assets/gradientline1.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.table-devices tr:nth-child(odd) {
  background: #636262;
  background-image: url("../../assets/images//phone_assets/gradientline2.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.team-page-th {
  padding-right: 20px;
}

.info-container {
  padding-right: 20px;
}

.footer-table {
  width: 800px;
  background-image: url("../../assets/images/table-bottom-bar.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left right !important;
  background-size: contain !important;
  height: 30px;
}

.hide-table {
  position: absolute;
  left: 800px;
  height: 35px;
  width: 50px;
  top: calc(50% - 25px);
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
  background-image: url("../../assets/images/handle.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

.hide-table .arrow-table {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/right.svg");
  background-repeat: no-repeat;
  background-position: 25px center;
  background-size: 50% 50%;
  cursor: pointer;
}

.phone-extension {
  display: none;
}

@media screen and (max-width: 1024px) {
  .table-container {
    top: calc(110vh + 60px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
    padding-bottom: 100px;
  }
  body {
    overflow: scroll !important;
    background: #a00a05;
  }
  .hide-table {
    display: none;
  }
  .table-devices {
    display: block;
  }
  .table-devices tr:nth-child(1) {
    top: -39px;
    display: table-row;
    position: absolute;
    background-image: url("../../assets/images/phone_assets/table-top-bar-m.svg") !important;
    background-size: contain !important;
    background-position: bottom !important;
  }
  .footer-table {
    background-image: url("../../assets/images/phone_assets/table-bottom-bar-m.svg") !important;
    background-size: contain;
  }
  .table-devices tr:nth-child(1) th {
    display: table-cell;
    padding-top: 5px;
  }
  .phone-extension {
    display: block;
    z-index: 1;
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: -20px;
    background: #a00a05;
  }
  .rank-tb {
    padding-left: 20px;
  }
  .footer {
    height: 50px !important;
  }
  .footer .flag-bottom {
    height: 60px;
    width: 60px;
    -webkit-clip-path: circle(28px at center);
            clip-path: circle(28px at center);
    bottom: 20px;
  }
  .sponsor-phone {
    margin-top: 0px;
    background-image: url("../../assets/images/phone_assets/banner-phone.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300px;
    cursor: pointer;
  }
  .ranking-phone {
    background-image: url("../../assets/images/phone_assets/ranking-bar.svg");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 60px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 450px) and (orientation: landscape) {
  .table-container {
    top: calc(100vh + 150px);
  }
  .ranking-phone {
    height: 60px;
    background-position: bottom;
  }
  .footer-table {
    margin-top: -2px;
  }
}

@media screen and (max-width: 820px) {
  .table-devices tr td {
    max-width: calc(650px / 9);
    width: calc(650px / 9);
    height: 45px;
  }
  .table-devices tr:nth-child(1) {
    width: 650px;
  }
  .table-devices tr:nth-child(1) th {
    font-weight: 600;
    font-size: 13px;
    padding-top: 10px;
    max-width: calc(650px / 9);
    width: calc(650px / 9);
  }
  .footer-table {
    width: 650px;
    background-position: center;
    background-size: cover !important;
    height: 18px;
  }
  .devices-col,
  .devices-col-no {
    font-size: 14px;
  }
  .table-container {
    top: 125vh;
  }
  .ranking-phone {
    background-size: contain;
    height: 50px;
    background-position: bottom;
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  .table-container {
    top: 115vh;
  }
  .table-devices tr:nth-child(1) th {
    padding-top: 24px !important;
  }
}

@media screen and (max-width: 820px) and (max-height: 450px) and (orientation: landscape) {
  .table-container {
    top: calc(100vh + 150px);
  }
  .ranking-phone {
    background-position: bottom;
  }
}

@media screen and (max-width: 680px) {
  .table-devices tr td {
    max-width: calc(550px / 9);
    width: calc(550px / 9);
  }
  .table-devices tr:nth-child(1) {
    width: 550px;
  }
  .table-devices tr:nth-child(1) th {
    font-weight: 600;
    font-size: 13px;
    padding-top: 10px;
    max-width: calc(550px / 9);
    width: calc(550px / 9);
  }
  .footer-table {
    width: 550px;
    background-position: center;
    background-size: cover !important;
    height: 14px;
  }
  .devices-col,
  .devices-col-no {
    font-size: 14px;
  }
}

@media screen and (max-width: 680px) and (max-height: 450px) and (orientation: landscape) {
  .table-container {
    top: calc(100vh + 150px);
  }
  .ranking-phone {
    background-position: bottom;
  }
}

@media screen and (max-width: 600px) {
  .table-devices tr td {
    max-width: calc(450px / 9);
    width: calc(450px / 9);
  }
  .table-devices tr:nth-child(1) {
    width: 450px;
  }
  .table-devices tr:nth-child(1) th {
    font-weight: 600;
    font-size: 11px;
    padding-top: 18px;
    max-width: calc(450px / 9);
    width: calc(450px / 9);
  }
  .footer-table {
    width: 450px;
    background-position: center;
    background-size: cover !important;
    height: 12px;
  }
  .devices-col,
  .devices-col-no {
    font-size: 11px;
  }
  .follow-button {
    height: 20px;
    width: 20px;
    min-height: 15px;
    min-width: 15px;
  }
  .sponsor-phone {
    height: 200px;
  }
}

@media screen and (max-width: 600px) and (max-height: 450px) and (orientation: landscape) {
  .table-container {
    top: calc(100vh + 150px);
  }
  .ranking-phone {
    background-position: bottom;
  }
}

@media screen and (max-width: 480px) {
  .table-devices tr td {
    max-width: calc(350px / 9);
    width: calc(350px / 9);
  }
  .table-devices tr:nth-child(1) {
    width: 350px;
  }
  .table-devices tr:nth-child(1) th {
    font-weight: 600;
    font-size: 11px;
    padding-top: 18px;
    max-width: calc(350px / 9);
    width: calc(350px / 9);
  }
  .footer-table {
    width: 350px;
    background-position: center;
    background-size: cover !important;
    height: 9px;
  }
  .devices-col,
  .devices-col-no {
    font-size: 11px;
  }
  .follow-button {
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
  }
  .sponsor-phone {
    height: 200px;
  }
  .table-container {
    top: calc(100vh + 70px);
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 480px) and (max-height: 450px) and (orientation: landscape) {
  .table-container {
    top: calc(100vh + 80px);
  }
  .ranking-phone {
    background-position: bottom;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .follow-button,
    .satelliteButton {
      -webkit-transition: none !important;
      transition: none !important;
    }
  }
}

@media all and (display-mode: fullscreen) and (max-width: 480px) {
  .table-container {
    top: calc(100vh + 130px);
    padding-bottom: 0px;
  }
}
