.footer {
  width: 100%;
  position: absolute;
  bottom: 0%;
  height: 70px;
  transition: all 1s ease;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Montserrat";
}
.flag-bottom {
  // background-image: url("../../assets/images/contest_statuses/green_final.gif");
  height: 100px;
  width: 100px;
  clip-path: circle(46px at center);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 25px;
  left: 15px;
}
.event-info-bottom {
  font-size: 18px;
  font-weight: 500;
  margin-left: 120px;
  text-transform: uppercase;
  bottom: 50%;
  position: absolute;
  transform: translateY(50%);
  color: white;
}
.ticker-info-bottom {
  width: calc(50vw - 150px) !important;
  position: absolute !important;
  right: 0 !important;
  bottom: 50% !important;
  transform: translateY(50%) !important;
}
.marquee {
  font-size: 20px;
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 1120px) {
  .event-info-bottom {
    font-size: 16px;
  }
  .marquee {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .ticker-info-bottom {
    width: calc(50vw - 105px) !important;
  }
  .event-info-bottom {
    font-size: 12px;
    margin-left: 80px;
  }
}
@media screen and (max-width: 724px) {
  .marquee {
    font-size: 12px;
  }
  .ticker-info-bottom {
    width: calc(50vw - 105px) !important;
  }
  .event-info-bottom {
    font-size: 12px;
    margin-left: 20px;
    bottom: 37.5%;
  }
}
@media screen and (max-width: 464px) {
  .event-info-bottom {
    display: none;
  }
}
