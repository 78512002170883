$base-color: #b0101c;
$shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
$big_font: calc(0.390625vw * 12);
$distance_font_mobile: calc(0.390625vw * 6);
$_1105_font: calc(0.390625vw * 4);
$small_font: calc(0.390625vw * 6);
$big_font_noScale: 30px;
$small_font_noScale: 15px;
$_10px: 0.390625vw;
$distance_font: 20px;
$screen_height: 1440px;
$screen_width: 2560px;
$margin_left: 20px;
$medium_font: 25px;
$width_rank: 50px;
$height_rank: 40px;
$battery_percent_font: 20px;
$special_green: #76aa3a;
$s_grey: #6d6d6d;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.more-info-sms-pop-up {
  position: absolute;
  display: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  top: 0%;
  left: 0%;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  transition: scale(0);

  .container-info {
    left: 50%;
    position: absolute;
    height: 650px;
    width: 600px;
    background-color: white;
    box-shadow: $shadow;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: 100px 550px;
    grid-template-columns: 300px 300px;
    .inner-header {
      background-image: url("http://ranking.ecogp.eu/assets/logo/top-bar.svg");
      background-size: cover;
      background-position: top center;
      height: 100px;
      display: grid;
      grid-row: 1/2;
      grid-column: 1/3;
      margin-top: -1px;
      p {
        grid-column: 1/2;
        color: white;
        font-size: $big_font_noScale;
        font-weight: 700;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        margin: -10px 0 0 $margin_left;
      }
      div {
        grid-column: 2/3;
        background-image: url("http://ranking.ecogp.eu/assets/logo/X-Close.svg");
        background-size: cover;
        background-position: right center;
        align-self: center;
        justify-self: end;
        height: 40px;
        width: 40px;
        cursor: pointer;
        margin: -40px $margin_left 0 $margin_left;

        clip-path: circle(20px);
        transition: 1s all ease-in-out;
        &:hover {
          background-color: black;
        }
      }
    }
    .inner-left-side {
      grid-row: 2/3;
      grid-column: 1/2;
      height: 100%;
      .inner-inner-header {
        font-weight: 700;
        font-size: $medium_font;
        color: $base-color;
        text-transform: none;

        margin: 0 $margin_left 0 $margin_left;
      }
      .inner-inner-app-info {
        height: 100px;
        width: 100%;
        display: flex;
        margin: 0 0 $margin_left $margin_left;
        .inner-inner-qr {
          background-image: url("http://ranking.ecogp.eu/assets/logo/app-qr.jpg");
          background-size: contain;
          background-position: left center;
          height: 100px;
          width: 40%;
        }
        .inner-inner-app-link {
          background-image: url("http://ranking.ecogp.eu/assets/logo/Google.jpg");
          background-size: contain;
          background-position: right center;
          height: 100px;
          width: 50%;
          cursor: pointer;
        }
      }
      .inner-inner-about-more {
        background-color: $base-color;
        width: 80%;
        height: 50px;
        margin: 0 $margin_left 0 $margin_left;
        p {
          color: white;
          font-size: 18px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          cursor: pointer;
          transition: all 1s ease-in-out;
          &:hover {
            font-size: 19px;
            color: black;
          }
        }
      }
      .inner-inner-text {
        font-weight: 500;
        margin: 0 $margin_left $margin_left $margin_left;

        text-transform: none;
      }
    }
    .inner-right-side {
      grid-row: 2/3;
      grid-column: 2/3;
      background-image: url("http://ranking.ecogp.eu/assets/logo/phone.png");
      background-size: contain;
      background-position: top center;
      height: 100%;
      width: 90%;
      margin: 0 0 0 0;
    }
  }
}

p,
span {
  color: #6d6d6d;
  font-size: $small_font_noScale;
}
.bar {
  p {
    margin: 0 !important;
    font-weight: bold;
    padding: 3px 0 3px 0;
  }
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */

.mBody {
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: absolute;
  background-color: #fff;
  transition: all 1s ease-in-out;

  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  overflow-y: scroll !important;
  header {
    font-family: "Montserrat", sans-serif;
    min-height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    $big_font: calc(0.390625vw * 6);
    $small_font: calc(0.390625vw * 3);
    margin: 0px calc(#{$margin_left * 2}) 0 calc(#{$margin_left * 2});
    .details {
      height: 100%;
      flex: 3;
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: start;
      .close-button {
        background-image: url("../../assets/images/X-Close.svg");
        background-size: contain;
        background-repeat: no-repeat;
        border: white 0px;
        background-color: #fff;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        transition: background-image 0.5s ease-in-out;
        &:hover {
          background-image: url("../../assets/images/X-CloseWhite.svg");
        }
      }
      .flag {
        height: calc(#{$_10px} * 5);
        width: calc(#{$_10px} * 5);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 $_10px 0 $_10px;
        background-image: url("../../assets/images/countries/Germany.svg");
      }
      .team-name,
      .team-number,
      .full-name,
      .inside-line {
        font-size: $big_font;
        font-style: "Montserrat";
        font-weight: 900;
        color: #b0101c;
        text-transform: uppercase;
        margin: 0 $_10px 0 $_10px;
      }
      .team-name {
        margin: 0 $_10px 0 0;
      }
      .full-name {
        font-weight: 300;
      }
      .inside-line {
        margin: 0;
        font-weight: 300;
      }
    }
    .social-media {
      display: grid;

      align-items: center;
      justify-content: end;
      justify-self: end;
      position: relative !important;
      .yt,
      .instr,
      .fb {
        height: 15px;
        width: 15px;
        margin: 0 10px 0 10px;
        cursor: pointer;
        z-index: 1;
        flex-wrap: wrap;
      }
      .fb {
        grid-row: 1/2;
        background-image: url("../../assets/images/phone_assets/facebook.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .instr {
        grid-row: 1/2;
        background-image: url("../../assets/images/phone_assets/instagram.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .yt {
        grid-row: 1/2;
        background-image: url("../../assets/images/phone_assets/youtube.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .share {
        grid-row: 1/2;
        background-image: url("../../assets/images/phone_assets/share.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .share {
        height: 45px;
        width: 45px;
        cursor: pointer;
        z-index: 1;
      }
      .square {
        position: absolute !important;
        right: 10% !important;
        z-index: 0;
        width: 140px;
        height: 35px;
        border: 1.5px solid $base-color;
        background: #fff;
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;
      }
    }
  }

  .second-body {
    display: grid;
    grid-gap: 30px;
    //   grid-row-gap: 50px;
    margin-top: 0px;
    grid-template-columns: 1fr 1.2fr;
    margin: -20px calc(#{$margin_left * 2}) 0 calc(#{$margin_left * 2});
    .Minfo {
      min-width: 1125px;
      min-height: 576px;
      grid-row: 2/3;
      grid-column: 1/2;

      display: grid;
      grid-template-rows: 1.2fr 0.4fr;
      background-color: #fff;
      .first-top {
        grid-row: 1/2;
        grid-column: 1/6;
        //  min-width: 80%;
        min-height: 10%;
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 15px;
        grid-template-columns: 285px 1fr 1.6fr 1fr;
        grid-template-rows: 100px 100px 100px 30px 280px;
        margin-top: 0px;

        .driver-info {
          grid-row: 1/5;
          grid-column: 1/2;
          background-color: white;
          display: flex;
          width: 100%;
          flex-direction: column;
          position: relative;
          box-shadow: $shadow;
          margin-bottom: 0px;
          .avatar {
            background-image: url("../../assets/images/assets/driver.jpg");
            min-width: 80%;
            height: 220px;
            margin: 0px $margin_left 0px $margin_left;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .driver-status {
            margin: 20px $margin_left -10px $margin_left;
            text-align: left;
            color: $special_green;

            font-size: $small_font_noScale;
            font-weight: 900;
          }
          .name {
            margin: 20px $margin_left 10px $margin_left;
          }

          .driving-time {
            background-color: $special_green;
            position: absolute;
            bottom: 0%;
            width: 100%;
            p {
              padding: 10px $margin_left 10px 0;
              margin-left: $margin_left;
              width: 100%;
              text-align: left;
              color: white;

              font-size: calc(#{$medium_font - 5px});
              font-weight: 400;
            }
          }
        }
        .laps,
        .flag-status,
        .pic {
          width: 100%;
          box-shadow: $shadow;
          font-style: "Montserrat";
          font-weight: 300;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            width: 80%;
            margin: 10px auto 0 20px;
          }

          .laps-nr,
          .pic-nr {
            font-size: $big_font_noScale;
            font-weight: 900;
            color: $base-color;
            width: 80%;
            margin: 0 auto 0 20px;
          }
        }
        .laps {
          grid-row: 3/4;
          grid-column: 2/3;
        }
        .flag-status {
          grid-row: 1/2;
          grid-column: 4/5;
          margin: -0px 0px 0px 0;
          background-color: white;
          display: flex;
          justify-content: top;
          align-items: flex-start;
          flex-direction: row;

          p {
            margin: 20px -20px 10px $margin_left;
          }
          box-shadow: $shadow;
          .flag-status-gif {
            width: 120px;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0px $margin_left 0px 0px;
          }
        }
        .pic {
          grid-row: 1/2;
          grid-column: 2/3;
          margin-right: -5px;
        }
        .car-class {
          grid-row: 1/2;
          grid-column: 3/4;
          box-shadow: $shadow;
          display: grid;
          margin: 0px 0 0px 0px;
          .car-class-info {
            margin: $margin_left 0 5px $margin_left;
          }
          .car-logo {
            grid-row: 1/3;
            grid-column: 2/3;
            height: 40px;
            width: 40px;
            background-image: url("../../assets/images/assets/hyundai-logo.png");
            background-position: center;
            justify-self: flex-end;
            background-repeat: no-repeat;
            background-size: contain;
            margin: $margin_left $margin_left 0px 0;
          }
          .car-model {
            margin: 0px -200px 0 $margin_left;
            font-size: $big_font_noScale;
            font-weight: 900;
            color: $base-color;
          }
        }
        .last-lap-penalty {
          grid-row: 2/3;
          grid-column: 2/3;
          min-height: 30%;
          margin: 0px 0 0px 0;
          background-color: white;
          display: flex;
          justify-content: top;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          .text {
            margin-top: 20px;
            margin-bottom: 0;
            span {
              margin: 2px 0 2px 0;
              color: #6d6d6d;
            }
            display: flex;
            flex-direction: column;
          }
          p {
            flex: 1;
            width: 100%;
            margin: 5px $margin_left 0px $margin_left;
          }
          .penalty {
            font-size: $big_font_noScale;
            font-style: "Montserrat";
            font-weight: 900;
            color: $base-color;
            display: flex;
            justify-self: center;
            align-items: flex-end;
            margin-bottom: 10px;
          }
          box-shadow: $shadow;
        }
        .total-penalty {
          grid-row: 2/3;
          grid-column: 4/5;
          margin-top: 0px;
          box-shadow: $shadow;
          display: flex;
          flex-direction: column;
          p {
            margin: 15px 20px 0 20px;
            span {
              margin: 2px 0 2px 0;
              color: #6d6d6d;
            }
          }
          .total {
            margin: 20px 20px 0px 20px;
            color: $base-color;
            font-weight: 900;
            font-size: $big_font_noScale;
          }
        }
        .sponsor2 {
          grid-row: 3/4;
          grid-column: 3/4;
          box-shadow: $shadow;
          border: 0;
          outline: 0;
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
          background-image: url("../../assets/images/sponsors/Logos380x100px/Logos380x100px/80edays.png");

          outline: 0;
          border: 0;

          margin: 0px 0px 0px 0;
        }
        .like-rank {
          grid-row: 3/4;
          grid-column: 4/5;
          width: 100%;
          box-shadow: $shadow;
          margin: 0px 0px 0px 0px;
          display: grid;
          grid-template-rows: 1 1 !important;
          grid-template-columns: 1 1.5 !important;
          .rank-info {
            width: 100%;
            height: 100%;
            text-align: left;
            margin: 10px 0 0 20px;
            grid-row: 1/2;
            grid-column: 1/3;
          }
          .votes {
            grid-row: 2/3;
            grid-column: 2/3;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin: 0px 0px 15px -10px;
          }
          .rank-like {
            grid-row: 2/3;
            grid-column: 1/2;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin: 0px 0px 10px $margin_left;
            color: $base-color;
            font-weight: 900;
            text-align: right;
            font-size: $big_font_noScale;
          }
        }
        .time-left {
          grid-row: 2/3;
          grid-column: 3/4;
          margin-top: 0px;
          box-shadow: $shadow;
          display: flex;
          flex-direction: column;
          p {
            width: 100%;
            text-align: left;
            height: 100%;
            display: flex;
            align-items: flex-start;
            margin: 20px 20px 0px 20px;
          }
          .left {
            margin: 0;
            width: 100%;
            justify-content: flex-start;
            height: 100%;
            display: flex;
            align-items: flex-end;
            color: $base-color;
            font-weight: 900;
            margin: -20px 20px 10px 20px;
            font-size: $big_font_noScale;
            color: rgb(22, 17, 17);
          }
        }
        .ticker-text {
          grid-row: 4/5;
          grid-column: 2/5;
          box-shadow: $shadow;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .ticker-text-p {
            width: 100%;

            font-size: calc(#{$medium_font - 10px});
            font-weight: 400;
            font-style: "Montserrat", sans-serif;
            color: $s_grey;
            // animation: ticker 10s infinite linear;
          }
        }
        .qr {
          // min-height: 30%;
          grid-row: 5/6;
          grid-column: 1/2;

          box-shadow: $shadow;
          margin: 10px 0px 20px 0px;
          display: flex;
          .main-qr-sms {
            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: end;
            .qr-img {
              flex: 1;

              background-image: url("../../assets/general_photos/qr-vote.png");
              width: 100%;
              height: 300px;
              transform: scale(1.2) translateY(20px) translateX(25px);
              margin: 10px 0 0 20px;

              //  background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .sms-code-container {
              flex: 1;
              .sms-code-title {
                display: none;
              }
              .sms-code {
                margin-top: 70px;
                align-self: flex-start;
                margin-left: 20px;
                margin-right: 10px;
                margin-bottom: 0;
                margin-bottom: 10px;
                justify-self: center;
                color: $base-color;
                font-weight: 900;
                font-size: 20px;
              }
            }
          }
          .more-info {
            height: 100%;
            text-decoration: none;
            flex: 0.2;
            background-color: $base-color;
            color: white;
            width: 30%;
            p {
              height: 50%;
              width: 200%;
              font-size: 20px;
              color: white;
              transform: rotate(-90deg) translateX(-35%) translateY(-35%);
              //   margin-left: -70px;
              //  margin-top: 108px;
              font-weight: 900;
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;
              transition: 0.5s color linear;
            }

            cursor: pointer;

            &:hover {
              p {
                color: rgb(0, 0, 0);
              }
            }
          }
        }
        .second-top {
          grid-row: 5/6;
          grid-column: 2/5;
          display: grid;
          grid-template-columns: 1fr 1.2fr 1fr;
          margin: 0px 0 -10px 0;
          .behind-you {
            position: relative;
            grid-column: 1/2;
            width: 100%;
            background-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0,
              rgba(255, 255, 255, 1) 100%
            );
            box-shadow: $shadow;
            margin: 10px 0px 30px 0px;
            font-style: "Montserrat";
            font-weight: 300;
            display: grid;
            grid-template-rows: 1 1 1 1;
            grid-template-columns: 1 1;
            .behind-team {
              grid-row: 1/2;
              grid-column: 1/2;
              font-size: $big_font_noScale;
              margin: 5px 0 0 $margin_left !important;
              justify-content: flex-start !important;
            }
            .rank {
              grid-row: 1/2;
              grid-column: 2/3;
              width: $width_rank;
              height: $height_rank;
              background-color: $base-color;
              display: flex;
              align-items: flex-end;
              justify-self: end;
              margin: 0px (calc(#{$margin_left+15px})) 0 0;
              .behind-rank {
                width: 100%;
                text-align: center;
                color: white;
                font-weight: 900;
                font-size: $medium_font;
              }
            }
            .behind-distance {
              position: absolute;
              top: -70%;
              grid-row: 2/3;
              grid-column: 1/3;
              justify-self: center !important;
              p {
                width: 100%;
                height: 100%;
                align-self: flex-start;
                display: flex;
                justify-content: center !important;
                margin: 0px 0 0px 0 !important;
                font-size: $distance_font;
                font-weight: 300;
                text-transform: lowercase;
              }
            }
            .behind-car {
              position: absolute;
              top: 0%;
              grid-row: 3/4;
              grid-column: 1/3;
              height: 23px;
              width: 100%;
              transform: scale(2.5) rotateY(180deg);
              display: flex;
              align-self: center;
              margin-top: 0;
              margin: 0px 0 0 0 !important;
              background-color: transparent;
              background-image: url("../../assets/images/assets/car2.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .behind-battery {
              // width: 100%;
              grid-row: 4/5;
              grid-column: 1/3;
              display: flex;
              align-items: center !important;
              justify-items: center !important;
              .behind-img-battery {
                width: 100%;
                margin: 20px 0px 5px 0px !important;
                transform: scale(0.6);
                // background-image: url("../../assets/images/assets/40.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                .behind-battery-percent {
                  font-size: calc(#{$battery_percent_font+ 5px});
                  color: white;
                  font-weight: bold;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  margin: 0 0 0 15px;
                }
              }
            }
            p {
              font-weight: 900;
              color: $base-color;
              width: 80%;
            }
          }
          .you {
            grid-column: 2/3;
            flex: 1;
            width: 100%;
            box-shadow: $shadow;
            font-size: $big_font_noScale;
            font-style: "Montserrat";
            font-weight: 300;
            display: grid;
            p {
              font-weight: 900;
              color: $base-color;
              width: 80%;
              font-size: $big_font_noScale;
              background-color: transparent;
            }
            position: relative;
            margin: 0px 0px 10px 0px;
            transform: scaleX(1.1);
            z-index: 100;
            background-color: #fff;
            grid-template-columns: 1 1;
            grid-template-rows: 1 1 1;
            .rank {
              grid-row: 1/2;
              grid-column: 2/3;

              width: $width_rank;
              height: $height_rank;
              background-color: $base-color;
              display: flex;
              align-items: flex-end;
              justify-self: end;
              margin: 0 $margin_left 0 0;
              .my-rank {
                width: 100%;
                text-align: center;
                color: white;
                font-weight: 900;
                font-size: $medium_font;
              }
            }
            .my-car {
              grid-row: 2/3;
              grid-column: 1/3;
              height: 100%;
              width: 100%;
              transform: scale(1.1) rotateY(180deg);
              display: flex;
              align-self: center;
              margin-top: 0;
              background-color: transparent;
              background-image: url("../../assets/images/assets/car2.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .my-team {
              grid-row: 1/2;
              grid-column: 1/2;
              align-self: center;
              background-color: transparent;
              //  width: 100%;
              display: flex;
              justify-self: start;
              margin: -50px 0 0 20px;
            }
            .arrow-left {
              position: absolute;
              top: 23%;
              z-index: 1000;
              left: -16%;
              width: 30%;
              height: 20px;
              transform: rotateY(180deg) scale(0.8);
              background-image: url("../../assets/images/assets/arrow.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .arrow-right {
              position: absolute;
              z-index: 999;
              top: 23%;
              right: -14%;
              width: 30%;
              height: 20px;
              transform: rotateY(180deg) scale(0.8);
              background-image: url("../../assets/images/assets/arrow.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .my-battery {
              grid-row: 3/4;
              grid-column: 1/3;
              width: 160px;
              height: 60%;
              margin: 25px 0 0px 0;
              align-self: center;
              justify-self: center;
              background-color: white;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              // background-image: url("../../assets/images/assets/NA.svg");
              .my-battery-percent {
                font-size: calc(#{$battery_percent_font});
                color: white;
                font-weight: bold;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 0 0 11px;
              }
            }
          }
          .next-to-you {
            position: relative;
            grid-column: 3/4;
            flex: 1;
            width: 100%;
            box-shadow: $shadow;
            margin: 10px 0px 30px 0;
            font-style: "Montserrat";
            font-weight: 300;
            display: grid;
            grid-template-rows: 1 1 1 1;
            grid-template-columns: 1 1;
            p {
              font-weight: 900;
              color: $base-color;
              width: 80%;

              font-size: $big_font_noScale;
            }
            background-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0,
              rgba(255, 255, 255, 1) 100%
            );
            .rank {
              grid-row: 1/2;
              grid-column: 2/3;
              width: $width_rank;
              height: $height_rank;
              background-color: $base-color;
              display: flex;
              justify-self: end;
              align-items: flex-end;
              margin: 0 $margin_left 0 0px;
              .next-rank {
                width: 100%;
                text-align: center;
                color: white;
                font-size: $medium_font;
              }
            }
            .next-team {
              grid-column: 1/2;
              grid-row: 1/2;
              font-size: $big_font_noScale;
              margin: 0 !important;
              width: 100%;
              height: 100%;
              padding: 0 !important;
              margin: 5px 0px 0px 30px !important;
              justify-self: start !important;
              display: block !important;
            }
            .next-distance {
              position: absolute;
              top: -70%;
              right: 42%;
              grid-column: 1/3;
              grid-row: 2/3;
              p {
                width: 100%;
                font-size: $distance_font;
                display: flex;
                justify-self: center !important;
                margin: 0px 0px 0 0px !important;
                text-transform: lowercase;
                font-weight: 300;
              }
            }
            .next-car {
              position: absolute;
              top: 0%;
              grid-row: 3/4;
              grid-column: 1/3;
              height: 23px;
              width: 100%;
              transform: scale(2.5) rotateY(180deg);
              display: flex;
              align-self: center;
              margin-top: 0;
              margin: 0px 0 0 0 !important;
              background-color: transparent;
              background-image: url("../../assets/images/assets/car2.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
            .next-battery {
              //  width: 100%;
              grid-column: 1/3;
              grid-row: 4/5;
              display: flex;
              align-items: center !important;
              .next-img-battery {
                width: 100%;
                margin: 20px 0px 5px 0px !important;
                transform: scale(0.6);
                // background-image: url("../../assets/images/assets/NA.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                .next-battery-percent {
                  font-size: calc(#{$battery_percent_font +5px});
                  color: white;
                  font-weight: bold;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0px 0 0 20px;
                }
              }
            }
          }
        }
      }
    }
    .second-inner {
      grid-row: 2/3;
      grid-column: 1/6;
      grid-column-gap: 0px;
      display: grid;
      grid-template-columns: 1fr;
      margin: -20px 0 10px 0;
      .carousel {
        grid-row: 1/2;
        grid-column: 1/2;
        height: calc(#{$screen_height} * 0.15);
        background-color: #fff;
        margin: 30px 0px -20px 0;
        display: flex;
        box-shadow: $shadow;
        justify-content: center;
        align-items: center;
        .left-arrow {
          width: 40px;
          height: 40px;
          background-image: url("../../assets/images/assets/arrow-left.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 20px;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }
        .driver {
          margin: 0 15px;
          height: 200px;
          width: 128px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          transition: 1s all ease-in-out;
          .driver-avatar {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            height: 128px;
            background-image: url("../../assets/images/assets/driver.jpg");
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .driver-name {
            margin-top: 0px;
            text-align: left;
            width: 100%;
          }
          .bar {
            background-color: black;
            width: 100%;
            margin-top: -15px;
            //height: 100%;
            p {
              width: 100%;
              text-align: center;
              color: white;

              font-weight: 400;
            }
          }
          .s-bar {
            // display: none;
            background-color: black;
            width: 100%;
            margin-top: 0px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            p {
              width: 100%;
              text-align: center;
              color: white;
              font-weight: 400;
              padding: 2px 0;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
            }
          }
        }
        #dr4 {
          .bar {
            background-color: $special_green;
          }
          .s-bar {
            display: block;
          }
        }
        #dr1 {
          .bar {
            background-color: red;
          }
          .s-bar {
            display: block;
          }
        }
        .right-arrow {
          width: 40px;
          height: 40px;
          background-image: url("../../assets/images/assets/arrow-right.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 20px;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .main-img {
      grid-row: 1/3;
      grid-column: 2/3;
      width: 100%;
      background-image: url("../../assets/images/assets/team-photo.jpg");
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 30px;
      margin-left: 0px;
      margin-bottom: -5px;
    }
    .sponsor {
      grid-row: 3/4;
      grid-column: 1/2;
      box-shadow: $shadow;
      border: 0;
      outline: 0;

      background-image: url("../../assets/images/sponsors/1200x200/TRON.png");
      transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      outline: 0;
      border: 0;

      margin: 10px 0px 30px 0;
    }
    .motivation {
      grid-row: 3/4;
      grid-column: 2/3;
      width: 100%;
      min-height: calc(#{$screen_height} * 0.13);
      margin-top: 10px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      box-shadow: $shadow;
      margin-right: 30px;
      .motivation-info {
        margin: 20px;
        font-size: $big_font_noScale;
        color: $base-color;
        margin-top: 30px;
        font-weight: 900;
      }
      .info-motivation {
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 15px;
        text-transform: none;
        margin-left: 20px;
      }
    }
  }
}

// team info breakpoints

//just chrome, opera and others
@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .selector:not(*:root),
  .Mbody {
    display: block;
  }
  .Mbody .second-body .Minfo .first-top .second-top .next-you .behind-team {
    margin-left: 200px;
  }
  .Mbody
    .second-body
    .Minfo
    .first-top
    .second-top
    .behind-you
    .behind-distance
    p {
    margin-left: 210px;
  }
  .Mbody
    .second-body
    .Minfo
    .first-top
    .second-top
    .behind-you
    .behind-battery
    .behind-img-battery {
    margin-left: 180px;
  }
}
//mozila
@-moz-document url-prefix() {
  .Mbody {
    display: block;
  }
  .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
    margin-left: 40px;
  }
  .Mbody
    .second-body
    .Minfo
    .first-top
    .second-top
    .behind-you
    .behind-distance
    p {
    margin-left: 20px;
  }
  .Mbody
    .second-body
    .Minfo
    .first-top
    .second-top
    .behind-you
    .behind-battery
    .behind-img-battery {
    margin-left: -10px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      margin-left: 200px;
    }
    .Mbody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-distance
      p {
      margin-left: 210px;
    }
    .Mbody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-battery
      .behind-img-battery {
      margin-left: 180px;
    }
  }
}
//first break-point smaller screens
@media not all and (min-resolution: 0.001dpcm) and (max-width: 1800px) {
  @supports (-webkit-appearance: none) {
    .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      width: 100%;
      margin: 0;
      /* text-align: right; */
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      margin-top: 10px;
    }
    .Mbody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-distance
      p {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      padding-right: 35px;
    }
    .Mbody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-battery {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      //  margin-top: 30px;
      // padding-right: 35px;
    }
  }
}
@media screen and (max-width: 1800px) {
  .mBody .second-body .Minfo .first-top .driver-info .avatar {
    background-size: contain;
  }
  .mBody {
    overflow-x: hidden !important;

    .second-body {
      display: grid;
      .Minfo {
        width: 100%;
        grid-column: 1/3;
        grid-row: 1/2;
        margin-top: 30px !important;
        .first-top {
          .sponsor2 {
            box-shadow: none;
            background-size: contain;
          }
        }
      }
      .main-img {
        margin-top: 10px;
        min-height: 900px;
        grid-row: 3/4;
        grid-column: 1/3;
      }
      .sponsor {
        grid-row: 4/5;
        grid-column: 1/3;
        height: calc(0.390625vw * 50);
        background-position: top;
        background-size: contain;
        box-shadow: none;
        margin-bottom: 0;
      }
      .motivation {
        grid-row: 2/3;
        grid-column: 1/3;
        margin-bottom: 0px;
        min-height: 0px;
      }
    }
  }

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .selector:not(*:root),
    .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      width: 100%;
      margin: 0;
      /* text-align: right; */
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      margin-top: 10px;
    }
    .mBody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-distance
      p {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      padding-right: 35px;
    }
    .mBody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-battery {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      //  margin-top: 30px;
      // padding-right: 35px;
    }
  }
  @-moz-document url-prefix() {
    .mBody .second-body .Mnfo .first-top .second-top .behind-you .behind-team {
      margin-left: -20px;
      padding: 0;
      margin-right: 0;
    }
    .mBody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-distance
      p {
      margin-left: 0px;
    }
    .mBody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-battery
      .behind-img-battery {
      margin-left: -10px;
    }
  }
}
// second break-point laptops, smaller screns

// third break-point tablets,phones
@media screen and (max-width: 1200px) {
  $width_rank: 30px;
  $height_rank: 40px;
  $medium_font: 15px;
  $big_font: calc(0.390625vw * 7);

  .mBody {
    position: relative;

    p,
    span {
      font-size: $_1105_font;
    }
    header {
      margin-right: 5px;
      margin-left: 5px;
      flex-direction: column;
      p {
        font-size: $small_font;
      }
      .details {
        width: 100%;
        display: grid;
        grid-template-columns: 80px 1fr 50px;
        margin-top: 10px;
        margin-bottom: -40px;
        .close-button {
          background-image: url("../../assets/images/X-Close.svg");
          background-size: contain;
          background-repeat: no-repeat;
          height: calc(#{$_10px} * 12);
          width: calc(#{$_10px} * 12);
          background-color: #fff;
          grid-column: 4/6;
          grid-row: 1/2;
        }
        .team-name {
          margin-left: 0;
          grid-column: 2/4;
          grid-row: 1/2;
          font-size: $big_font;
          margin-top: 0;
          margin-bottom: 40px;
          align-self: flex-end;
        }
        .flag {
          margin-left: 0;

          grid-column: 1/2;
          grid-row: 1/4;
          justify-self: center;
          align-self: center;
          height: calc(#{$_10px} * 12);
          width: calc(#{$_10px} * 12);
        }
        .team-number {
          font-size: $big_font;
          margin-left: 0;
          grid-row: 1/2;
          grid-column: 2/3;

          margin-top: 20px;
        }
        .full-name {
          font-size: $big_font;
          margin-left: 0;
          grid-row: 1/4;
          grid-column: 2/3;
          margin-top: 45px;
          margin-bottom: 0;
          align-self: flex-start;
        }
        .inside-line {
          display: none;
        }
      }

      .social-media {
        position: absolute;
        right: 0%;
        margin-top: 20px;
        transform: translateY(1650%);
      }
    }
    .second-body {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: -80px;
      margin-left: 5px;
      margin-right: 5px;
      .main-img {
        min-height: 720px;
        width: 100%;
        grid-row: 1/2;
        grid-column: 1/2;
        margin: 0;
        margin-top: 80px;
      }
      .Minfo {
        order: 2;

        display: block !important;
        min-width: 50px !important;
        margin: 0px 0 0 0 !important;
        grid-row: 2/3;
        grid-column: 1/2;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        .first-top {
          grid-row: 1/2;
          grid-column: 1/2;
          width: 100% !important;
          grid-template-rows: 50px 50px 50px 80px 250px 30px 150px 150px 100px;
          grid-template-columns: 0.2fr 0.2fr 0.3fr 0.3fr 0.2fr 0.2fr;
          grid-column-gap: 20px;
          grid-row-gap: calc(#{$_10px}* 6);
          .driver-info {
            grid-row: 1/5;
            grid-column: 1/3;
            .avatar {
              height: 150px;
              background-position: top left;
            }
            .driver-status {
              position: absolute;
              margin-bottom: 0;
              margin-top: 0;
              bottom: 17%;
            }
          }

          .sponsor2 {
            grid-row: 7/8;
            grid-column: 3/5;
            background-size: contain;
            box-shadow: none;
            background-color: transparent;
          }
          .laps {
            grid-row: 1/2;

            grid-column: 3/7;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            p {
              margin: 0 $margin_left 0 $margin_left;
            }
            .laps-nr {
              margin: 0;
              font-size: $big_font;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              margin-right: 20px;
            }
          }
          .flag-status {
            br {
              display: none;
            }
            grid-row: 4/5;
            grid-column: 3/7;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            margin: 0 0 0 0 !important;
            p {
              margin: 0 $margin_left 0 $margin_left;
            }
            .flag-status-gif {
              font-size: $big_font;
              width: 100%;
              height: 90%;
              display: flex;
              margin: 0;
              margin-right: 0;
              background-position: right center;
              margin-right: 20px;
            }
          }
          .car-class {
            grid-row: 9/10;
            grid-column: 1/4;

            .car-model {
              font-size: $big_font;
            }
          }
          .pic {
            grid-row: 2/3;
            grid-column: 3/7;

            flex-direction: row;
            justify-content: center;
            align-items: center;
            p {
              margin: 0 $margin_left 0 $margin_left;
            }
            .pic-nr {
              font-size: $big_font;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              margin-right: 20px;
            }
          }
          .last-lap-penalty {
            grid-row: 7/8;
            grid-column: 1/3;
            .text {
              margin: 10px 0 0 20px;
              flex-wrap: nowrap;
              .span {
                width: 50%;
              }
            }
            p {
              margin: 0;
              margin-left: 20px;
            }
            .penalty {
              height: 100%;
              font-size: $big_font;
              align-items: flex-end;
              margin-bottom: 10px;
            }
          }
          .total-penalty {
            grid-row: 7/8;
            grid-column: 5/7;
            p {
              padding: 0;
              flex: 1;
              margin-top: 10px;
            }
            .total {
              flex: 1;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: start;
              align-items: flex-end;
              margin-bottom: 10px;
              margin-top: 20px;
              padding: 0;
              font-size: $big_font;
            }
          }
          .time-left {
            grid-row: 3/4;
            grid-column: 3/7;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            p {
              display: flex;
              align-items: center;
              padding: 0;
              margin: 0 $margin_left 0 $margin_left;
              font-weight: 300;
            }
            .left {
              margin: 0;
              font-size: $big_font;
              width: 100%;
              display: flex;
              justify-content: flex-end;

              align-items: center;
              margin-right: 20px;
            }
          }
          .like-rank {
            grid-row: 9/10;
            grid-column: 4/7;
            .rank-info {
              margin: $margin_left 0 0 $margin_left;
            }
            .rank-like {
              font-size: $big_font;
            }
          }
          .second-top {
            grid-row: 5/6;
            grid-column: 1/7;
            margin: 0px 0 0px 0;
            .next-to-you {
              .next-team {
                font-size: $big_font;
              }
            }

            .you {
              .my-team {
                margin-top: -40px;
                font-size: $big_font;
              }
            }

            .behind-you {
              .behind-team {
                font-size: $big_font;
              }
            }
          }
          .ticker-text {
            grid-row: 6/7;
            grid-column: 1/7;
            margin: -15px 0 0 0;
          }
          .qr {
            grid-row: 8/9;
            grid-column: 1/7;
            margin: 0px 0 0px 0;
            .main-qr-sms {
              flex-direction: row;
              justify-content: unset;
              flex: 2;

              .qr-img {
                flex: 0;
                order: 1;
                height: 70%;
                min-width: 20%;
                margin: 10px 0 0 10px;
                //margin: 15px 0 20px 20px;

                display: flex;
              }
              .sms-code-container {
                order: 2;
                height: 100%;
                flex: 0;
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                margin-top: 0px;
                .sms-code-title {
                  flex: 1;
                  margin: 10px 0 0 20px;

                  font-weight: 300;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .sms-code {
                  flex: 1;
                  margin: 0;
                  height: 100%;
                  font-size: $big_font;
                  display: flex;
                  justify-content: end;
                  flex-direction: row;
                  align-items: flex-end;

                  margin: 20px 0px 10px 20px;
                }
              }
              .qr-img {
                min-width: 120px !important;
              }
              .sms-code-container {
                .sms-code-title {
                  margin-top: 20px;
                }
                .sms-code {
                  margin-bottom: 20px;
                }
              }
            }
            .more-info {
              flex: 0.5;
              height: 35%;

              margin: auto 20px auto 0px;
              p {
                height: 100%;
                font-size: $small_font;
                transform: rotate(0) translateX(-25%);
              }
            }
          }
        }
      }

      .second-inner {
        grid-row: 3/4;
        grid-column: 1/2;
        .carousel {
          margin: 40px 0 -40px 0;

          #dr2,
          #dr3,
          #dr6 {
            display: none;
          }
        }
      }

      .sponsor {
        margin: -10px 0 0px 0 !important;
        grid-row: 5/6;
        grid-column: 1/2;
        background-size: contain;
        background-position: top;
        box-shadow: none;
        height: calc(0.390625vw * 50);
      }

      .motivation {
        margin-top: -10px;
        margin-bottom: 0px;
        grid-row: 4/5;

        grid-column: 1/2;
        .motivation-info {
          font-size: $big_font;
        }
        .info-motivation {
          font-size: $_1105_font;
        }
      }
    }
  }
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .selector:not(*:root),
    .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      padding-right: 30px;
    }
    .mBody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-distance
      p {
      margin-left: 20px;
    }
    .Mbody
      .second-body
      .Minfo
      .first-top
      .second-top
      .behind-you
      .behind-battery {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .behind-img-battery {
        margin-left: 0px;
        margin-right: 15px;
      }
    }
    .Mbody .second-body .Minfo .first-top .status .status-nr {
      display: flex;
      justify-content: flex-end;
    }
    .Mbody .second-body .Minfo .first-top .laps .laps-nr {
      display: flex;
      justify-content: flex-end;
    }
    .Mbody .second-body .Minfo .first-top .rank .rank-nr {
      display: flex;
      justify-content: flex-end;
    }
    .Mbody .second-body .Minfo .first-top .pic .pic-nr {
      display: flex;
      justify-content: flex-end;
    }
    .Mbody .second-body .Minfo .first-inner .time-left .left {
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 980px) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
  .driver {
    vertical-align: middle;

    display: inline-block;
  }
  .carousel {
    display: -webkit-box !important;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 760px) {
  $_1105_font: calc(0.390625vw * 6);
  $distance_font_mobile: 15px;
  $big_font: calc(0.390625vw * 9);
  $battery_percent_font: 10px;
  $medium_font: 20px;
  $width_rank: 30px;
  $height_rank: 35px;
  $big_font: calc(0.390625vw * 9);
  $font_header: calc(0.390625vw * 12);
  .mBody {
    header {
      .details {
        grid-template-columns: 80px 1fr 50px;
        .close-button {
          height: calc(#{$_10px} * 23);
          width: calc(#{$_10px} * 23);
        }
        .full-name {
          font-size: $font_header !important ;
        }
        .team-name {
          font-size: $font_header !important;
        }
        .flag {
          height: calc(#{$_10px} * 23);
          width: calc(#{$_10px} * 23);
        }
      }
      .social-media {
        position: absolute;
        right: 0%;
        margin-top: 20px;
        transform: translateY(670%);
      }
    }
    .second-body {
      p,
      span {
        font-size: $_1105_font;
      }
      .Minfo {
        .first-top {
          grid-template-rows: 40px 40px 40px 60px 180px 10px 130px 120px 100px !important;
          grid-template-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
          .ticker-text {
            .ticker-text-p {
              font-size: calc(#{$medium_font - 5px}) !important;
            }
          }
          .driver-info {
            grid-column: 1/4 !important;
            .driving-time {
              p {
                font-size: calc(#{$medium_font - 5px});
                padding: 5px 0 5px 0 !important;
              }
            }
            .avatar {
              min-width: 0% !important;
              height: 100px !important;
            }
            .driver-status {
              bottom: 15% !important;
            }
          }
          .laps,
          .pic,
          .time-left,
          .flag-status {
            grid-column: 4/9 !important;
          }
          .second-top {
            grid-column: 1/9 !important;
            .behind-you {
              .behind-team {
                padding: 0 !important;
              }
              .rank {
                margin: 0 25px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                .behind-rank {
                  font-size: $medium_font !important;
                }
              }
              .behind-distance {
                p {
                  font-size: $distance_font_mobile !important;
                  padding: 0 !important;
                }
              }
              .behind-car {
                transform: scale(1.2) rotateY(180deg) !important;
              }
              .behind-battery {
                .behind-img-battery {
                  transform: scale(1) !important;
                  .behind-battery-percent {
                    font-size: $battery_percent_font !important;
                    margin-left: 10px !important;
                  }
                }
              }
            }
            .you {
              .my-car {
                transform: scale(1.2) rotateY(180deg) !important;
              }
              .my-battery {
                width: 100px !important;
                .my-battery-percent {
                  font-size: $battery_percent_font !important;
                }
              }
              .arrow-left,
              .arrow-right {
                top: 30% !important;
              }
              .rank {
                margin: 0 25px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                .my-rank {
                  font-size: $medium_font !important;
                }
              }
            }
            .next-to-you {
              .next-distance {
                right: 35% !important;
                p {
                  font-size: $distance_font_mobile !important;
                }
              }
              .next-car {
                transform: scale(1.2) rotateY(180deg) !important;
                padding: 0 !important;
              }
              .next-battery {
                .next-img-battery {
                  transform: scale(1) !important;
                  .next-battery-percent {
                    font-size: $battery_percent_font !important;
                  }
                }
              }
              .rank {
                margin: 0 25px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                margin: 0 10px 0 0 !important;
                .next-rank {
                  font-size: $medium_font !important;
                }
              }
            }
          }
          .last-lap-penalty {
            grid-column: 1/3 !important;
          }
          .sponsor2 {
            grid-column: 3/7 !important;
            background-size: contain;
            background-color: transparent;
            background-position: center;
            box-shadow: none;
          }
          .total-penalty {
            grid-column: 7/9 !important;
            .total {
              margin-top: 0px;
              margin-bottom: 5px;
            }
          }
          .qr {
            grid-column: 1/9 !important;

            .sms-code-container {
              margin-left: -20px !important ;
            }
            .more-info {
              flex: 1 !important;
            }
            .main-qr-sms {
              .sms-code {
                margin-bottom: 0px !important;
                margin-top: 10px !important;
                align-items: flex-start !important;
              }
              .qr-img {
                height: 60%;
              }
            }
          }
          .car-class {
            grid-column: 1/5 !important;
            .car-logo {
              width: 35px;
              height: 35px;
              margin: 20px 20px 0 0;
            }
          }
          .like-rank {
            grid-column: 5/9 !important;
          }
          .ticker-text {
            grid-column: 1/9 !important;
          }
        }
        .second-inner {
          .carousel {
            height: 220px;
            .driver {
              .driver-name {
                margin: 20px 0 0px 0;
              }
              height: 200px;
              width: 100px;
              .driver-avatar {
                width: 100%;
                height: 120px;
              }

              .bar,
              .s-bar {
                p {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
      .main-img {
        margin-top: 60px;
        min-height: 300px;
      }
      .motivation {
        .info-motivation {
          font-size: 15px;
        }
      }
    }
  }
}
@media screen and(max-width:600px) {
  .container-info {
    width: 300px !important;
    grid-template-columns: 150px 150px !important;
  }
  .inner-inner-app-info {
    position: absolute;
    left: 0px;
    bottom: 150px;
    width: 98% !important;
  }
  .text2 {
    position: absolute;
    bottom: 80px;
    left: 0px;
  }
  .inner-inner-about-more {
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 86% !important;
  }
  .inner-inner-header {
    font-size: 15px !important;
  }
  .inner-inner-text {
    font-size: 12px !important;
  }
  .inner-header {
    p {
      font-size: 25px !important;
    }
    div {
      margin: -20px 20px 0 20px !important;
    }
  }
}
@media screen and (max-width: 500px) {
  $_1105_font: calc(0.390625vw * 8);
  $distance_font_mobile: 15px;
  $big_font: calc(0.390625vw * 9);
  $battery_percent_font: 12px;
  $medium_font: 15px;
  $width_rank: 20px;
  $height_rank: 25px;
  $big_font: calc(0.390625vw * 10);
  $font_header: calc(0.390625vw * 14);
  .mBody {
    header {
      .details {
        grid-template-columns: 80px 2fr 50px;
        .close-button {
          height: calc(#{$_10px} * 25);
          width: calc(#{$_10px} * 25);
        }
        .full-name {
          font-size: $font_header !important ;
          margin-top: 30px;
        }
        .team-name {
          font-size: $font_header !important;
        }
        .flag {
          height: calc(#{$_10px} * 25);
          width: calc(#{$_10px} * 25);
        }
      }
    }
    .second-body {
      p,
      span {
        font-size: $_1105_font;
      }
      .Minfo {
        .laps {
          .laps-nr {
            font-size: $big_font !important;
          }
        }
        .pic {
          .pic-nr {
            font-size: $big_font !important;
          }
        }
        .time-left {
          .left {
            font-size: $big_font !important;
          }
        }
        .total-penalty {
          .total {
            font-size: $big_font !important;
          }
        }
        .car-class {
          .car-model {
            font-size: $big_font !important;
          }
        }
        .like-rank {
          .rank-like {
            font-size: $big_font !important;
          }
        }
        .last-lap-penalty {
          .penalty {
            font-size: $big_font !important;
          }
        }
        .first-top {
          grid-template-rows: 25px 25px 25px 50px 150px 10px 60px 120px 100px !important;
          grid-template-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
          .driver-info {
            .name {
              margin-top: 5px !important;
              margin-bottom: 5px !important;
            }
            .avatar {
              height: 70px !important;
            }
            .driver-status {
              display: none !important;
              font-size: 10px !important;
              bottom: 20% !important;
            }
            .driving-time {
              p {
                font-size: 13px !important;
              }
            }
          }
          .second-top {
            .behind-you {
              .behind-team {
                padding: 0 !important;
                font-size: $big_font;
              }
              .rank {
                margin: 0 10px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                .behind-rank {
                  font-size: $medium_font !important;
                }
              }
              .behind-distance {
                p {
                  font-size: calc(#{$distance_font_mobile - 5px}) !important;
                  padding: 0 !important;
                }
              }
              .behind-car {
                transform: scale(0.8) rotateY(180deg) !important;
              }
              .behind-battery {
                .behind-img-battery {
                  transform: scale(0.8) !important;
                  .behind-battery-percent {
                    font-size: $battery_percent_font !important;
                    margin-left: 10px !important;
                  }
                }
              }
            }
            .you {
              .my-car {
                transform: scale(0.9) rotateY(180deg) !important;
              }
              .my-battery {
                width: 80px !important;
                .my-battery-percent {
                  font-size: $battery_percent_font !important;
                }
              }
              .arrow-left,
              .arrow-right {
                top: 25% !important;
              }
              .my-team {
                margin-top: -20px !important;
                font-size: $big_font;
              }
              .rank {
                margin: 0 5px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                .my-rank {
                  font-size: $medium_font !important;
                }
              }
            }
            .next-to-you {
              .next-team {
                font-size: $big_font;
              }
              .next-distance {
                right: 35% !important;
                p {
                  font-size: calc(#{$distance_font_mobile - 5px}) !important;
                }
              }
              .next-car {
                transform: scale(0.8) rotateY(180deg) !important;
                padding: 0 !important;
              }
              .next-battery {
                .next-img-battery {
                  transform: scale(0.8) !important;
                  .next-battery-percent {
                    margin: 0 0 0 5px !important;
                    font-size: $battery_percent_font !important;
                  }
                }
              }
              .rank {
                margin: 0 25px 0 0 !important;
                width: $width_rank !important;
                height: $height_rank !important;
                margin: 0 10px 0 0 !important;
                .next-rank {
                  font-size: $medium_font !important;
                }
              }
            }
          }
          .qr {
            .main-qr-sms {
              flex: 1 !important;
              .sms-code {
                align-items: flex-end !important;
                margin-bottom: 20px !important;
                font-size: $big_font !important;
              }
            }
            grid-column: 1/9 !important;

            position: relative;
            .more-info {
              position: absolute;
              top: 50%;
              right: 0%;
              transform: translateY(-50%);
              p {
                padding: 0 20px 0 20px;
              }
            }
          }
          .total-penalty {
            p,
            .total {
              margin-left: 10px !important;
              margin-right: 0px !important;
            }
            .total {
              margin-top: 0px !important;
              margin-bottom: 5px !important;
            }
          }
          .last-lap-penalty {
            .text {
              margin-left: 10px !important;
            }
            .penalty {
              margin-left: 10px !important;
              margin-top: -7px;
            }
          }
          .like-rank {
            .rank-like,
            .votes {
              margin-bottom: 20px !important;
            }
          }
        }
      }

      .motivation {
        .motivation-info {
          font-size: $big_font !important;
        }
        .info-motivation {
          font-size: 13px;
        }
      }
    }
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .penalty {
    margin-bottom: 5px;
  }
}
@media screen and(max-width:500px) and (min-width: 400px) {
  .sms-code {
    margin-bottom: 10px !important;
  }
}
@media screen and(max-width:400px) {
  .sms-code-title {
    margin-left: 0px !important;
  }
  .sms-code {
    margin-left: 0px !important;
    margin-bottom: 20px !important;
  }
}
@media screen and(max-width:364px) {
  .mBody {
    header {
      .details {
        .full-name {
          margin-top: 20px;
        }
      }
    }
  }
}
