@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;900&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 62.5%;
}

.more-info-sms-pop-up {
  position: absolute;
  display: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  top: 0%;
  left: 0%;
  z-index: 999;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition: scale(0);
  transition: scale(0);
}

.more-info-sms-pop-up .container-info {
  left: 50%;
  position: absolute;
  height: 650px;
  width: 600px;
  background-color: white;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 100px 550px;
      grid-template-rows: 100px 550px;
  -ms-grid-columns: 300px 300px;
      grid-template-columns: 300px 300px;
}

.more-info-sms-pop-up .container-info .inner-header {
  background-image: url("http://ranking.ecogp.eu/assets/logo/top-bar.svg");
  background-size: cover;
  background-position: top center;
  height: 100px;
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  margin-top: -1px;
}

.more-info-sms-pop-up .container-info .inner-header p {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  color: white;
  font-size: 30px;
  font-weight: 700;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: -10px 0 0 20px;
}

.more-info-sms-pop-up .container-info .inner-header div {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  background-image: url("http://ranking.ecogp.eu/assets/logo/X-Close.svg");
  background-size: cover;
  background-position: right center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: end;
      justify-self: end;
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: -40px 20px 0 20px;
  -webkit-clip-path: circle(20px);
          clip-path: circle(20px);
  -webkit-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
}

.more-info-sms-pop-up .container-info .inner-header div:hover {
  background-color: black;
}

.more-info-sms-pop-up .container-info .inner-left-side {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  height: 100%;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-header {
  font-weight: 700;
  font-size: 25px;
  color: #b0101c;
  text-transform: none;
  margin: 0 20px 0 20px;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-app-info {
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 20px 20px;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-app-info .inner-inner-qr {
  background-image: url("http://ranking.ecogp.eu/assets/logo/app-qr.jpg");
  background-size: contain;
  background-position: left center;
  height: 100px;
  width: 40%;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-app-info .inner-inner-app-link {
  background-image: url("http://ranking.ecogp.eu/assets/logo/Google.jpg");
  background-size: contain;
  background-position: right center;
  height: 100px;
  width: 50%;
  cursor: pointer;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-about-more {
  background-color: #b0101c;
  width: 80%;
  height: 50px;
  margin: 0 20px 0 20px;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-about-more p {
  color: white;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-about-more p:hover {
  font-size: 19px;
  color: black;
}

.more-info-sms-pop-up .container-info .inner-left-side .inner-inner-text {
  font-weight: 500;
  margin: 0 20px 20px 20px;
  text-transform: none;
}

.more-info-sms-pop-up .container-info .inner-right-side {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  background-image: url("http://ranking.ecogp.eu/assets/logo/phone.png");
  background-size: contain;
  background-position: top center;
  height: 100%;
  width: 90%;
  margin: 0 0 0 0;
}

p,
span {
  color: #6d6d6d;
  font-size: 15px;
}

.bar p {
  margin: 0 !important;
  font-weight: bold;
  padding: 3px 0 3px 0;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.mBody {
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: absolute;
  background-color: #fff;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  overflow-y: scroll !important;
}

.mBody header {
  font-family: "Montserrat", sans-serif;
  min-height: 8vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px calc(40px) 0 calc(40px);
}

.mBody header .details {
  height: 100%;
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.mBody header .details .close-button {
  background-image: url("../../assets/images/X-Close.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border: white 0px;
  background-color: #fff;
  height: 40px;
  width: 40px;
  margin-right: 20px;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
}

.mBody header .details .close-button:hover {
  background-image: url("../../assets/images/X-CloseWhite.svg");
}

.mBody header .details .flag {
  height: calc(0.39062vw * 5);
  width: calc(0.39062vw * 5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0.39062vw 0 0.39062vw;
  background-image: url("../../assets/images/countries/Germany.svg");
}

.mBody header .details .team-name,
.mBody header .details .team-number,
.mBody header .details .full-name,
.mBody header .details .inside-line {
  font-size: calc(0.390625vw * 6);
  font-style: "Montserrat";
  font-weight: 900;
  color: #b0101c;
  text-transform: uppercase;
  margin: 0 0.39062vw 0 0.39062vw;
}

.mBody header .details .team-name {
  margin: 0 0.39062vw 0 0;
}

.mBody header .details .full-name {
  font-weight: 300;
}

.mBody header .details .inside-line {
  margin: 0;
  font-weight: 300;
}

.mBody header .social-media {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -ms-grid-column-align: end;
      justify-self: end;
  position: relative !important;
}

.mBody header .social-media .yt,
.mBody header .social-media .instr,
.mBody header .social-media .fb {
  height: 15px;
  width: 15px;
  margin: 0 10px 0 10px;
  cursor: pointer;
  z-index: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.mBody header .social-media .fb {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  background-image: url("../../assets/images/phone_assets/facebook.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody header .social-media .instr {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  background-image: url("../../assets/images/phone_assets/instagram.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody header .social-media .yt {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  background-image: url("../../assets/images/phone_assets/youtube.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody header .social-media .share {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  background-image: url("../../assets/images/phone_assets/share.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody header .social-media .share {
  height: 45px;
  width: 45px;
  cursor: pointer;
  z-index: 1;
}

.mBody header .social-media .square {
  position: absolute !important;
  right: 10% !important;
  z-index: 0;
  width: 140px;
  height: 35px;
  border: 1.5px solid #b0101c;
  background: #fff;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.mBody .second-body {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  margin-top: 0px;
  -ms-grid-columns: 1fr 1.2fr;
      grid-template-columns: 1fr 1.2fr;
  margin: -20px calc(40px) 0 calc(40px);
}

.mBody .second-body .Minfo {
  min-width: 1125px;
  min-height: 576px;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1.2fr 0.4fr;
      grid-template-rows: 1.2fr 0.4fr;
  background-color: #fff;
}

.mBody .second-body .Minfo .first-top {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-column: 1/6;
  min-height: 10%;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  -ms-grid-columns: 285px 1fr 1.6fr 1fr;
      grid-template-columns: 285px 1fr 1.6fr 1fr;
  -ms-grid-rows: 100px 100px 100px 30px 280px;
      grid-template-rows: 100px 100px 100px 30px 280px;
  margin-top: 0px;
}

.mBody .second-body .Minfo .first-top .driver-info {
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  grid-row: 1/5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin-bottom: 0px;
}

.mBody .second-body .Minfo .first-top .driver-info .avatar {
  background-image: url("../../assets/images/assets/driver.jpg");
  min-width: 80%;
  height: 220px;
  margin: 0px 20px 0px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .driver-info .driver-status {
  margin: 20px 20px -10px 20px;
  text-align: left;
  color: #76aa3a;
  font-size: 15px;
  font-weight: 900;
}

.mBody .second-body .Minfo .first-top .driver-info .name {
  margin: 20px 20px 10px 20px;
}

.mBody .second-body .Minfo .first-top .driver-info .driving-time {
  background-color: #76aa3a;
  position: absolute;
  bottom: 0%;
  width: 100%;
}

.mBody .second-body .Minfo .first-top .driver-info .driving-time p {
  padding: 10px 20px 10px 0;
  margin-left: 20px;
  width: 100%;
  text-align: left;
  color: white;
  font-size: calc(20px);
  font-weight: 400;
}

.mBody .second-body .Minfo .first-top .laps,
.mBody .second-body .Minfo .first-top .flag-status,
.mBody .second-body .Minfo .first-top .pic {
  width: 100%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  font-style: "Montserrat";
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.mBody .second-body .Minfo .first-top .laps p,
.mBody .second-body .Minfo .first-top .flag-status p,
.mBody .second-body .Minfo .first-top .pic p {
  width: 80%;
  margin: 10px auto 0 20px;
}

.mBody .second-body .Minfo .first-top .laps .laps-nr,
.mBody .second-body .Minfo .first-top .laps .pic-nr,
.mBody .second-body .Minfo .first-top .flag-status .laps-nr,
.mBody .second-body .Minfo .first-top .flag-status .pic-nr,
.mBody .second-body .Minfo .first-top .pic .laps-nr,
.mBody .second-body .Minfo .first-top .pic .pic-nr {
  font-size: 30px;
  font-weight: 900;
  color: #b0101c;
  width: 80%;
  margin: 0 auto 0 20px;
}

.mBody .second-body .Minfo .first-top .laps {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
}

.mBody .second-body .Minfo .first-top .flag-status {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-column: 4/5;
  margin: -0px 0px 0px 0;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: top;
      -ms-flex-pack: top;
          justify-content: top;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
}

.mBody .second-body .Minfo .first-top .flag-status p {
  margin: 20px -20px 10px 20px;
}

.mBody .second-body .Minfo .first-top .flag-status .flag-status-gif {
  width: 120px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0px 20px 0px 0px;
}

.mBody .second-body .Minfo .first-top .pic {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  margin-right: -5px;
}

.mBody .second-body .Minfo .first-top .car-class {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  display: -ms-grid;
  display: grid;
  margin: 0px 0 0px 0px;
}

.mBody .second-body .Minfo .first-top .car-class .car-class-info {
  margin: 20px 0 5px 20px;
}

.mBody .second-body .Minfo .first-top .car-class .car-logo {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  height: 40px;
  width: 40px;
  background-image: url("../../assets/images/assets/hyundai-logo.png");
  background-position: center;
  justify-self: flex-end;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px 20px 0px 0;
}

.mBody .second-body .Minfo .first-top .car-class .car-model {
  margin: 0px -200px 0 20px;
  font-size: 30px;
  font-weight: 900;
  color: #b0101c;
}

.mBody .second-body .Minfo .first-top .last-lap-penalty {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  min-height: 30%;
  margin: 0px 0 0px 0;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: top;
      -ms-flex-pack: top;
          justify-content: top;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
}

.mBody .second-body .Minfo .first-top .last-lap-penalty .text {
  margin-top: 20px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mBody .second-body .Minfo .first-top .last-lap-penalty .text span {
  margin: 2px 0 2px 0;
  color: #6d6d6d;
}

.mBody .second-body .Minfo .first-top .last-lap-penalty p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  margin: 5px 20px 0px 20px;
}

.mBody .second-body .Minfo .first-top .last-lap-penalty .penalty {
  font-size: 30px;
  font-style: "Montserrat";
  font-weight: 900;
  color: #b0101c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 10px;
}

.mBody .second-body .Minfo .first-top .total-penalty {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-column: 4/5;
  margin-top: 0px;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mBody .second-body .Minfo .first-top .total-penalty p {
  margin: 15px 20px 0 20px;
}

.mBody .second-body .Minfo .first-top .total-penalty p span {
  margin: 2px 0 2px 0;
  color: #6d6d6d;
}

.mBody .second-body .Minfo .first-top .total-penalty .total {
  margin: 20px 20px 0px 20px;
  color: #b0101c;
  font-weight: 900;
  font-size: 30px;
}

.mBody .second-body .Minfo .first-top .sponsor2 {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  border: 0;
  outline: 0;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
  transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
  background-image: url("../../assets/images/sponsors/Logos380x100px/Logos380x100px/80edays.png");
  outline: 0;
  border: 0;
  margin: 0px 0px 0px 0;
}

.mBody .second-body .Minfo .first-top .like-rank {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-column: 4/5;
  width: 100%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin: 0px 0px 0px 0px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1 1 !important;
      grid-template-rows: 1 1 !important;
  -ms-grid-columns: 1 1.5 !important;
      grid-template-columns: 1 1.5 !important;
}

.mBody .second-body .Minfo .first-top .like-rank .rank-info {
  width: 100%;
  height: 100%;
  text-align: left;
  margin: 10px 0 0 20px;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}

.mBody .second-body .Minfo .first-top .like-rank .votes {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 0px 0px 15px -10px;
}

.mBody .second-body .Minfo .first-top .like-rank .rank-like {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 0px 0px 10px 20px;
  color: #b0101c;
  font-weight: 900;
  text-align: right;
  font-size: 30px;
}

.mBody .second-body .Minfo .first-top .time-left {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  margin-top: 0px;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mBody .second-body .Minfo .first-top .time-left p {
  width: 100%;
  text-align: left;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 20px 20px 0px 20px;
}

.mBody .second-body .Minfo .first-top .time-left .left {
  margin: 0;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  color: #b0101c;
  font-weight: 900;
  margin: -20px 20px 10px 20px;
  font-size: 30px;
  color: #161111;
}

.mBody .second-body .Minfo .first-top .ticker-text {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/5;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2/5;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.mBody .second-body .Minfo .first-top .ticker-text .ticker-text-p {
  width: 100%;
  font-size: calc(15px);
  font-weight: 400;
  font-style: "Montserrat", sans-serif;
  color: #6d6d6d;
}

.mBody .second-body .Minfo .first-top .qr {
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-row: 5/6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin: 10px 0px 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mBody .second-body .Minfo .first-top .qr .main-qr-sms {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.mBody .second-body .Minfo .first-top .qr .main-qr-sms .qr-img {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-image: url("../../assets/general_photos/qr-vote.png");
  width: 100%;
  height: 300px;
  -webkit-transform: scale(1.2) translateY(20px) translateX(25px);
          transform: scale(1.2) translateY(20px) translateX(25px);
  margin: 10px 0 0 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code-title {
  display: none;
}

.mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code {
  margin-top: 70px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 0;
  margin-bottom: 10px;
  -ms-grid-column-align: center;
      justify-self: center;
  color: #b0101c;
  font-weight: 900;
  font-size: 20px;
}

.mBody .second-body .Minfo .first-top .qr .more-info {
  height: 100%;
  text-decoration: none;
  -webkit-box-flex: 0.2;
      -ms-flex: 0.2;
          flex: 0.2;
  background-color: #b0101c;
  color: white;
  width: 30%;
  cursor: pointer;
}

.mBody .second-body .Minfo .first-top .qr .more-info p {
  height: 50%;
  width: 200%;
  font-size: 20px;
  color: white;
  -webkit-transform: rotate(-90deg) translateX(-35%) translateY(-35%);
          transform: rotate(-90deg) translateX(-35%) translateY(-35%);
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.5s color linear;
  transition: 0.5s color linear;
}

.mBody .second-body .Minfo .first-top .qr .more-info:hover p {
  color: black;
}

.mBody .second-body .Minfo .first-top .second-top {
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-row: 5/6;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2/5;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.2fr 1fr;
      grid-template-columns: 1fr 1.2fr 1fr;
  margin: 0px 0 -10px 0;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you {
  position: relative;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), to(white));
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, white 100%);
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin: 10px 0px 30px 0px;
  font-style: "Montserrat";
  font-weight: 300;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1 1 1 1;
      grid-template-rows: 1 1 1 1;
  -ms-grid-columns: 1 1;
      grid-template-columns: 1 1;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  font-size: 30px;
  margin: 5px 0 0 20px !important;
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .rank {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 50px;
  height: 40px;
  background-color: #b0101c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  justify-self: end;
  margin: 0px calc(35px) 0 0;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .rank .behind-rank {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 900;
  font-size: 25px;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-distance {
  position: absolute;
  top: -70%;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  -ms-grid-column-align: center !important;
      justify-self: center !important;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-distance p {
  width: 100%;
  height: 100%;
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  margin: 0px 0 0px 0 !important;
  font-size: 20px;
  font-weight: 300;
  text-transform: lowercase;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-car {
  position: absolute;
  top: 0%;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  height: 23px;
  width: 100%;
  -webkit-transform: scale(2.5) rotateY(180deg);
          transform: scale(2.5) rotateY(180deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 0;
  margin: 0px 0 0 0 !important;
  background-color: transparent;
  background-image: url("../../assets/images/assets/car2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  justify-items: center !important;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery {
  width: 100%;
  margin: 20px 0px 5px 0px !important;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery .behind-battery-percent {
  font-size: calc(25px);
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin: 0 0 0 15px;
}

.mBody .second-body .Minfo .first-top .second-top .behind-you p {
  font-weight: 900;
  color: #b0101c;
  width: 80%;
}

.mBody .second-body .Minfo .first-top .second-top .you {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  font-size: 30px;
  font-style: "Montserrat";
  font-weight: 300;
  display: -ms-grid;
  display: grid;
  position: relative;
  margin: 0px 0px 10px 0px;
  -webkit-transform: scaleX(1.1);
          transform: scaleX(1.1);
  z-index: 100;
  background-color: #fff;
  -ms-grid-columns: 1 1;
      grid-template-columns: 1 1;
  -ms-grid-rows: 1 1 1;
      grid-template-rows: 1 1 1;
}

.mBody .second-body .Minfo .first-top .second-top .you p {
  font-weight: 900;
  color: #b0101c;
  width: 80%;
  font-size: 30px;
  background-color: transparent;
}

.mBody .second-body .Minfo .first-top .second-top .you .rank {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 50px;
  height: 40px;
  background-color: #b0101c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  justify-self: end;
  margin: 0 20px 0 0;
}

.mBody .second-body .Minfo .first-top .second-top .you .rank .my-rank {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 900;
  font-size: 25px;
}

.mBody .second-body .Minfo .first-top .second-top .you .my-car {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(1.1) rotateY(180deg);
          transform: scale(1.1) rotateY(180deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 0;
  background-color: transparent;
  background-image: url("../../assets/images/assets/car2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .you .my-team {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -ms-flex-item-align: center;
      align-self: center;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: start;
  margin: -50px 0 0 20px;
}

.mBody .second-body .Minfo .first-top .second-top .you .arrow-left {
  position: absolute;
  top: 23%;
  z-index: 1000;
  left: -16%;
  width: 30%;
  height: 20px;
  -webkit-transform: rotateY(180deg) scale(0.8);
          transform: rotateY(180deg) scale(0.8);
  background-image: url("../../assets/images/assets/arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .you .arrow-right {
  position: absolute;
  z-index: 999;
  top: 23%;
  right: -14%;
  width: 30%;
  height: 20px;
  -webkit-transform: rotateY(180deg) scale(0.8);
          transform: rotateY(180deg) scale(0.8);
  background-image: url("../../assets/images/assets/arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .you .my-battery {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  width: 160px;
  height: 60%;
  margin: 25px 0 0px 0;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .you .my-battery .my-battery-percent {
  font-size: calc(20px);
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 0 0 11px;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you {
  position: relative;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin: 10px 0px 30px 0;
  font-style: "Montserrat";
  font-weight: 300;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1 1 1 1;
      grid-template-rows: 1 1 1 1;
  -ms-grid-columns: 1 1;
      grid-template-columns: 1 1;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), to(white));
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, white 100%);
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you p {
  font-weight: 900;
  color: #b0101c;
  width: 80%;
  font-size: 30px;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .rank {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 50px;
  height: 40px;
  background-color: #b0101c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 0 20px 0 0px;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .rank .next-rank {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 25px;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-team {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  font-size: 30px;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 5px 0px 0px 30px !important;
  -ms-grid-column-align: start !important;
      justify-self: start !important;
  display: block !important;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance {
  position: absolute;
  top: -70%;
  right: 42%;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance p {
  width: 100%;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-self: center !important;
  margin: 0px 0px 0 0px !important;
  text-transform: lowercase;
  font-weight: 300;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-car {
  position: absolute;
  top: 0%;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  height: 23px;
  width: 100%;
  -webkit-transform: scale(2.5) rotateY(180deg);
          transform: scale(2.5) rotateY(180deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 0;
  margin: 0px 0 0 0 !important;
  background-color: transparent;
  background-image: url("../../assets/images/assets/car2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery {
  width: 100%;
  margin: 20px 0px 5px 0px !important;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery .next-battery-percent {
  font-size: calc(25px);
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 0 0 20px;
}

.mBody .second-body .second-inner {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-column: 1/6;
  grid-column-gap: 0px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  margin: -20px 0 10px 0;
}

.mBody .second-body .second-inner .carousel {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  height: calc(1440px * 0.15);
  background-color: #fff;
  margin: 30px 0px -20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mBody .second-body .second-inner .carousel .left-arrow {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/images/assets/arrow-left.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.mBody .second-body .second-inner .carousel .left-arrow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mBody .second-body .second-inner .carousel .driver {
  margin: 0 15px;
  height: 200px;
  width: 128px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
}

.mBody .second-body .second-inner .carousel .driver .driver-avatar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 128px;
  background-image: url("../../assets/images/assets/driver.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mBody .second-body .second-inner .carousel .driver .driver-name {
  margin-top: 0px;
  text-align: left;
  width: 100%;
}

.mBody .second-body .second-inner .carousel .driver .bar {
  background-color: black;
  width: 100%;
  margin-top: -15px;
}

.mBody .second-body .second-inner .carousel .driver .bar p {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400;
}

.mBody .second-body .second-inner .carousel .driver .s-bar {
  background-color: black;
  width: 100%;
  margin-top: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mBody .second-body .second-inner .carousel .driver .s-bar p {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400;
  padding: 2px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mBody .second-body .second-inner .carousel #dr4 .bar {
  background-color: #76aa3a;
}

.mBody .second-body .second-inner .carousel #dr4 .s-bar {
  display: block;
}

.mBody .second-body .second-inner .carousel #dr1 .bar {
  background-color: red;
}

.mBody .second-body .second-inner .carousel #dr1 .s-bar {
  display: block;
}

.mBody .second-body .second-inner .carousel .right-arrow {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/images/assets/arrow-right.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 20px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.mBody .second-body .second-inner .carousel .right-arrow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mBody .second-body .main-img {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 100%;
  background-image: url("../../assets/images/assets/team-photo.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  margin-left: 0px;
  margin-bottom: -5px;
}

.mBody .second-body .sponsor {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  border: 0;
  outline: 0;
  background-image: url("../../assets/images/sponsors/1200x200/TRON.png");
  -webkit-transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
  transition: all 2s cubic-bezier(0.34, -0.02, 0.55, 0.96);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  outline: 0;
  border: 0;
  margin: 10px 0px 30px 0;
}

.mBody .second-body .motivation {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3/4;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  width: 100%;
  min-height: calc(1440px * 0.13);
  margin-top: 10px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
  margin-right: 30px;
}

.mBody .second-body .motivation .motivation-info {
  margin: 20px;
  font-size: 30px;
  color: #b0101c;
  margin-top: 30px;
  font-weight: 900;
}

.mBody .second-body .motivation .info-motivation {
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  text-transform: none;
  margin-left: 20px;
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .selector:not(*:root),
  .Mbody {
    display: block;
  }
  .Mbody .second-body .Minfo .first-top .second-top .next-you .behind-team {
    margin-left: 200px;
  }
  .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-distance
p {
    margin-left: 210px;
  }
  .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-battery
.behind-img-battery {
    margin-left: 180px;
  }
}

@-moz-document url-prefix() {
  .Mbody {
    display: block;
  }
  .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
    margin-left: 40px;
  }
  .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-distance
p {
    margin-left: 20px;
  }
  .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-battery
.behind-img-battery {
    margin-left: -10px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      margin-left: 200px;
    }
    .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-distance
p {
      margin-left: 210px;
    }
    .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-battery
.behind-img-battery {
      margin-left: 180px;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) and (max-width: 1800px) {
  @supports (-webkit-appearance: none) {
    .Mbody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
      width: 100%;
      margin: 0;
      /* text-align: right; */
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      padding-right: 20px;
      margin-top: 10px;
    }
    .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-distance
p {
      margin: 0;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      margin-top: 30px;
      padding-right: 35px;
    }
    .Mbody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-battery {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 1800px) {
  .mBody .second-body .Minfo .first-top .driver-info .avatar {
    background-size: contain;
  }
  .mBody {
    overflow-x: hidden !important;
  }
  .mBody .second-body {
    display: -ms-grid;
    display: grid;
  }
  .mBody .second-body .Minfo {
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    margin-top: 30px !important;
  }
  .mBody .second-body .Minfo .first-top .sponsor2 {
    -webkit-box-shadow: none;
            box-shadow: none;
    background-size: contain;
  }
  .mBody .second-body .main-img {
    margin-top: 10px;
    min-height: 900px;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
  }
  .mBody .second-body .sponsor {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4/5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    height: calc(0.390625vw * 50);
    background-position: top;
    background-size: contain;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 0;
  }
  .mBody .second-body .motivation {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    margin-bottom: 0px;
    min-height: 0px;
  }
  @-moz-document url-prefix() {
    .mBody .second-body .Mnfo .first-top .second-top .behind-you .behind-team {
      margin-left: -20px;
      padding: 0;
      margin-right: 0;
    }
    .mBody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-distance
p {
      margin-left: 0px;
    }
    .mBody
.second-body
.Minfo
.first-top
.second-top
.behind-you
.behind-battery
.behind-img-battery {
      margin-left: -10px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .mBody {
    position: relative;
  }
  .mBody p,
  .mBody span {
    font-size: calc(0.390625vw * 4);
  }
  .mBody header {
    margin-right: 5px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mBody header p {
    font-size: calc(0.390625vw * 6);
  }
  .mBody header .details {
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 80px 1fr 50px;
        grid-template-columns: 80px 1fr 50px;
    margin-top: 10px;
    margin-bottom: -40px;
  }
  .mBody header .details .close-button {
    background-image: url("../../assets/images/X-Close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: calc(0.39062vw * 12);
    width: calc(0.39062vw * 12);
    background-color: #fff;
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
    grid-column: 4/6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
  }
  .mBody header .details .team-name {
    margin-left: 0;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2/4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    font-size: calc(0.390625vw * 7);
    margin-top: 0;
    margin-bottom: 40px;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .mBody header .details .flag {
    margin-left: 0;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column-align: center;
        justify-self: center;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    height: calc(0.39062vw * 12);
    width: calc(0.39062vw * 12);
  }
  .mBody header .details .team-number {
    font-size: calc(0.390625vw * 7);
    margin-left: 0;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    margin-top: 20px;
  }
  .mBody header .details .full-name {
    font-size: calc(0.390625vw * 7);
    margin-left: 0;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    margin-top: 45px;
    margin-bottom: 0;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .mBody header .details .inside-line {
    display: none;
  }
  .mBody header .social-media {
    position: absolute;
    right: 0%;
    margin-top: 20px;
    -webkit-transform: translateY(1650%);
            transform: translateY(1650%);
  }
  .mBody .second-body {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin-top: -80px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .mBody .second-body .main-img {
    min-height: 720px;
    width: 100%;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    margin: 0;
    margin-top: 80px;
  }
  .mBody .second-body .Minfo {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    display: block !important;
    min-width: 50px !important;
    margin: 0px 0 0 0 !important;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
  }
  .mBody .second-body .Minfo .first-top {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    width: 100% !important;
    -ms-grid-rows: 50px 50px 50px 80px 250px 30px 150px 150px 100px;
        grid-template-rows: 50px 50px 50px 80px 250px 30px 150px 150px 100px;
    -ms-grid-columns: 0.2fr 0.2fr 0.3fr 0.3fr 0.2fr 0.2fr;
        grid-template-columns: 0.2fr 0.2fr 0.3fr 0.3fr 0.2fr 0.2fr;
    grid-column-gap: 20px;
    grid-row-gap: calc(0.39062vw* 6);
  }
  .mBody .second-body .Minfo .first-top .driver-info {
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1/5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
  }
  .mBody .second-body .Minfo .first-top .driver-info .avatar {
    height: 150px;
    background-position: top left;
  }
  .mBody .second-body .Minfo .first-top .driver-info .driver-status {
    position: absolute;
    margin-bottom: 0;
    margin-top: 0;
    bottom: 17%;
  }
  .mBody .second-body .Minfo .first-top .sponsor2 {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-row: 7/8;
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3/5;
    background-size: contain;
    -webkit-box-shadow: none;
            box-shadow: none;
    background-color: transparent;
  }
  .mBody .second-body .Minfo .first-top .laps {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    grid-column: 3/7;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .mBody .second-body .Minfo .first-top .laps p {
    margin: 0 20px 0 20px;
  }
  .mBody .second-body .Minfo .first-top .laps .laps-nr {
    margin: 0;
    font-size: calc(0.390625vw * 7);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-right: 20px;
  }
  .mBody .second-body .Minfo .first-top .flag-status {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4/5;
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    grid-column: 3/7;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0 0 0 !important;
  }
  .mBody .second-body .Minfo .first-top .flag-status br {
    display: none;
  }
  .mBody .second-body .Minfo .first-top .flag-status p {
    margin: 0 20px 0 20px;
  }
  .mBody .second-body .Minfo .first-top .flag-status .flag-status-gif {
    font-size: calc(0.390625vw * 7);
    width: 100%;
    height: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    margin-right: 0;
    background-position: right center;
    margin-right: 20px;
  }
  .mBody .second-body .Minfo .first-top .car-class {
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    grid-row: 9/10;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1/4;
  }
  .mBody .second-body .Minfo .first-top .car-class .car-model {
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .pic {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    grid-column: 3/7;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mBody .second-body .Minfo .first-top .pic p {
    margin: 0 20px 0 20px;
  }
  .mBody .second-body .Minfo .first-top .pic .pic-nr {
    font-size: calc(0.390625vw * 7);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-right: 20px;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-row: 7/8;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .text {
    margin: 10px 0 0 20px;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .text .span {
    width: 50%;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty p {
    margin: 0;
    margin-left: 20px;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .penalty {
    height: 100%;
    font-size: calc(0.390625vw * 7);
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 10px;
  }
  .mBody .second-body .Minfo .first-top .total-penalty {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-row: 7/8;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-column: 5/7;
  }
  .mBody .second-body .Minfo .first-top .total-penalty p {
    padding: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-top: 10px;
  }
  .mBody .second-body .Minfo .first-top .total-penalty .total {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 0;
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .time-left {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3/4;
    -ms-grid-column: 3;
    -ms-grid-column-span: 4;
    grid-column: 3/7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .mBody .second-body .Minfo .first-top .time-left p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 0 20px 0 20px;
    font-weight: 300;
  }
  .mBody .second-body .Minfo .first-top .time-left .left {
    margin: 0;
    font-size: calc(0.390625vw * 7);
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 20px;
  }
  .mBody .second-body .Minfo .first-top .like-rank {
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    grid-row: 9/10;
    -ms-grid-column: 4;
    -ms-grid-column-span: 3;
    grid-column: 4/7;
  }
  .mBody .second-body .Minfo .first-top .like-rank .rank-info {
    margin: 20px 0 0 20px;
  }
  .mBody .second-body .Minfo .first-top .like-rank .rank-like {
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .second-top {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-row: 5/6;
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
    margin: 0px 0 0px 0;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-team {
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-team {
    margin-top: -40px;
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .Minfo .first-top .ticker-text {
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;
    grid-row: 6/7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
    margin: -15px 0 0 0;
  }
  .mBody .second-body .Minfo .first-top .qr {
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    grid-row: 8/9;
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
    margin: 0px 0 0px 0;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .qr-img {
    -webkit-box-flex: 0;
        -ms-flex: 0;
            flex: 0;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    height: 70%;
    min-width: 20%;
    margin: 10px 0 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    height: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0;
            flex: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: 20px;
    margin-top: 0px;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code-title {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 10px 0 0 20px;
    font-weight: 300;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 0;
    height: 100%;
    font-size: calc(0.390625vw * 7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 20px 0px 10px 20px;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .qr-img {
    min-width: 120px !important;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code-title {
    margin-top: 20px;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code-container .sms-code {
    margin-bottom: 20px;
  }
  .mBody .second-body .Minfo .first-top .qr .more-info {
    -webkit-box-flex: 0.5;
        -ms-flex: 0.5;
            flex: 0.5;
    height: 35%;
    margin: auto 20px auto 0px;
  }
  .mBody .second-body .Minfo .first-top .qr .more-info p {
    height: 100%;
    font-size: calc(0.390625vw * 6);
    -webkit-transform: rotate(0) translateX(-25%);
            transform: rotate(0) translateX(-25%);
  }
  .mBody .second-body .second-inner {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .mBody .second-body .second-inner .carousel {
    margin: 40px 0 -40px 0;
  }
  .mBody .second-body .second-inner .carousel #dr2,
  .mBody .second-body .second-inner .carousel #dr3,
  .mBody .second-body .second-inner .carousel #dr6 {
    display: none;
  }
  .mBody .second-body .sponsor {
    margin: -10px 0 0px 0 !important;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-row: 5/6;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    background-size: contain;
    background-position: top;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: calc(0.390625vw * 50);
  }
  .mBody .second-body .motivation {
    margin-top: -10px;
    margin-bottom: 0px;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4/5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .mBody .second-body .motivation .motivation-info {
    font-size: calc(0.390625vw * 7);
  }
  .mBody .second-body .motivation .info-motivation {
    font-size: calc(0.390625vw * 4);
  }
}

@media screen and (max-width: 980px) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
  .driver {
    vertical-align: middle;
    display: inline-block;
  }
  .carousel {
    display: -webkit-box !important;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 760px) {
  .mBody header .details {
    -ms-grid-columns: 80px 1fr 50px;
        grid-template-columns: 80px 1fr 50px;
  }
  .mBody header .details .close-button {
    height: calc(0.39062vw * 23);
    width: calc(0.39062vw * 23);
  }
  .mBody header .details .full-name {
    font-size: calc(0.390625vw * 12) !important;
  }
  .mBody header .details .team-name {
    font-size: calc(0.390625vw * 12) !important;
  }
  .mBody header .details .flag {
    height: calc(0.39062vw * 23);
    width: calc(0.39062vw * 23);
  }
  .mBody header .social-media {
    position: absolute;
    right: 0%;
    margin-top: 20px;
    -webkit-transform: translateY(670%);
            transform: translateY(670%);
  }
  .mBody .second-body p,
  .mBody .second-body span {
    font-size: calc(0.390625vw * 6);
  }
  .mBody .second-body .Minfo .first-top {
    -ms-grid-rows: 40px 40px 40px 60px 180px 10px 130px 120px 100px !important;
        grid-template-rows: 40px 40px 40px 60px 180px 10px 130px 120px 100px !important;
    -ms-grid-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
        grid-template-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
  }
  .mBody .second-body .Minfo .first-top .ticker-text .ticker-text-p {
    font-size: calc(15px) !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 3 !important;
    grid-column: 1/4 !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .driving-time p {
    font-size: calc(15px);
    padding: 5px 0 5px 0 !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .avatar {
    min-width: 0% !important;
    height: 100px !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .driver-status {
    bottom: 15% !important;
  }
  .mBody .second-body .Minfo .first-top .laps,
  .mBody .second-body .Minfo .first-top .pic,
  .mBody .second-body .Minfo .first-top .time-left,
  .mBody .second-body .Minfo .first-top .flag-status {
    -ms-grid-column: 4 !important;
    -ms-grid-column-span: 5 !important;
    grid-column: 4/9 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 8 !important;
    grid-column: 1/9 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
    padding: 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .rank {
    margin: 0 25px 0 0 !important;
    width: 30px !important;
    height: 35px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .rank .behind-rank {
    font-size: 20px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-distance p {
    font-size: 15px !important;
    padding: 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-car {
    -webkit-transform: scale(1.2) rotateY(180deg) !important;
            transform: scale(1.2) rotateY(180deg) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery {
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery .behind-battery-percent {
    font-size: 10px !important;
    margin-left: 10px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-car {
    -webkit-transform: scale(1.2) rotateY(180deg) !important;
            transform: scale(1.2) rotateY(180deg) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-battery {
    width: 100px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-battery .my-battery-percent {
    font-size: 10px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .arrow-left,
  .mBody .second-body .Minfo .first-top .second-top .you .arrow-right {
    top: 30% !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .rank {
    margin: 0 25px 0 0 !important;
    width: 30px !important;
    height: 35px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .rank .my-rank {
    font-size: 20px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance {
    right: 35% !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance p {
    font-size: 15px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-car {
    -webkit-transform: scale(1.2) rotateY(180deg) !important;
            transform: scale(1.2) rotateY(180deg) !important;
    padding: 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery {
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery .next-battery-percent {
    font-size: 10px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .rank {
    margin: 0 25px 0 0 !important;
    width: 30px !important;
    height: 35px !important;
    margin: 0 10px 0 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .rank .next-rank {
    font-size: 20px !important;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1/3 !important;
  }
  .mBody .second-body .Minfo .first-top .sponsor2 {
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 4 !important;
    grid-column: 3/7 !important;
    background-size: contain;
    background-color: transparent;
    background-position: center;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .mBody .second-body .Minfo .first-top .total-penalty {
    -ms-grid-column: 7 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 7/9 !important;
  }
  .mBody .second-body .Minfo .first-top .total-penalty .total {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .mBody .second-body .Minfo .first-top .qr {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 8 !important;
    grid-column: 1/9 !important;
  }
  .mBody .second-body .Minfo .first-top .qr .sms-code-container {
    margin-left: -20px !important;
  }
  .mBody .second-body .Minfo .first-top .qr .more-info {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 !important;
            flex: 1 !important;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code {
    margin-bottom: 0px !important;
    margin-top: 10px !important;
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .qr-img {
    height: 60%;
  }
  .mBody .second-body .Minfo .first-top .car-class {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 4 !important;
    grid-column: 1/5 !important;
  }
  .mBody .second-body .Minfo .first-top .car-class .car-logo {
    width: 35px;
    height: 35px;
    margin: 20px 20px 0 0;
  }
  .mBody .second-body .Minfo .first-top .like-rank {
    -ms-grid-column: 5 !important;
    -ms-grid-column-span: 4 !important;
    grid-column: 5/9 !important;
  }
  .mBody .second-body .Minfo .first-top .ticker-text {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 8 !important;
    grid-column: 1/9 !important;
  }
  .mBody .second-body .Minfo .second-inner .carousel {
    height: 220px;
  }
  .mBody .second-body .Minfo .second-inner .carousel .driver {
    height: 200px;
    width: 100px;
  }
  .mBody .second-body .Minfo .second-inner .carousel .driver .driver-name {
    margin: 20px 0 0px 0;
  }
  .mBody .second-body .Minfo .second-inner .carousel .driver .driver-avatar {
    width: 100%;
    height: 120px;
  }
  .mBody .second-body .Minfo .second-inner .carousel .driver .bar p,
  .mBody .second-body .Minfo .second-inner .carousel .driver .s-bar p {
    font-size: 13px;
  }
  .mBody .second-body .main-img {
    margin-top: 60px;
    min-height: 300px;
  }
  .mBody .second-body .motivation .info-motivation {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .container-info {
    width: 300px !important;
    -ms-grid-columns: 150px 150px !important;
        grid-template-columns: 150px 150px !important;
  }
  .inner-inner-app-info {
    position: absolute;
    left: 0px;
    bottom: 150px;
    width: 98% !important;
  }
  .text2 {
    position: absolute;
    bottom: 80px;
    left: 0px;
  }
  .inner-inner-about-more {
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 86% !important;
  }
  .inner-inner-header {
    font-size: 15px !important;
  }
  .inner-inner-text {
    font-size: 12px !important;
  }
  .inner-header p {
    font-size: 25px !important;
  }
  .inner-header div {
    margin: -20px 20px 0 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .mBody header .details {
    -ms-grid-columns: 80px 2fr 50px;
        grid-template-columns: 80px 2fr 50px;
  }
  .mBody header .details .close-button {
    height: calc(0.39062vw * 25);
    width: calc(0.39062vw * 25);
  }
  .mBody header .details .full-name {
    font-size: calc(0.390625vw * 14) !important;
    margin-top: 30px;
  }
  .mBody header .details .team-name {
    font-size: calc(0.390625vw * 14) !important;
  }
  .mBody header .details .flag {
    height: calc(0.39062vw * 25);
    width: calc(0.39062vw * 25);
  }
  .mBody .second-body p,
  .mBody .second-body span {
    font-size: calc(0.390625vw * 8);
  }
  .mBody .second-body .Minfo .laps .laps-nr {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .pic .pic-nr {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .time-left .left {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .total-penalty .total {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .car-class .car-model {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .like-rank .rank-like {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .last-lap-penalty .penalty {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .first-top {
    -ms-grid-rows: 25px 25px 25px 50px 150px 10px 60px 120px 100px !important;
        grid-template-rows: 25px 25px 25px 50px 150px 10px 60px 120px 100px !important;
    -ms-grid-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
        grid-template-columns: 0.2fr 0.18fr 0.12fr 0.2fr 0.2fr 0.12fr 0.18fr 0.2fr !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .name {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .avatar {
    height: 70px !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .driver-status {
    display: none !important;
    font-size: 10px !important;
    bottom: 20% !important;
  }
  .mBody .second-body .Minfo .first-top .driver-info .driving-time p {
    font-size: 13px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-team {
    padding: 0 !important;
    font-size: calc(0.390625vw * 10);
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .rank {
    margin: 0 10px 0 0 !important;
    width: 20px !important;
    height: 25px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .rank .behind-rank {
    font-size: 15px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-distance p {
    font-size: calc(10px) !important;
    padding: 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-car {
    -webkit-transform: scale(0.8) rotateY(180deg) !important;
            transform: scale(0.8) rotateY(180deg) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery {
    -webkit-transform: scale(0.8) !important;
            transform: scale(0.8) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .behind-you .behind-battery .behind-img-battery .behind-battery-percent {
    font-size: 12px !important;
    margin-left: 10px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-car {
    -webkit-transform: scale(0.9) rotateY(180deg) !important;
            transform: scale(0.9) rotateY(180deg) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-battery {
    width: 80px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-battery .my-battery-percent {
    font-size: 12px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .arrow-left,
  .mBody .second-body .Minfo .first-top .second-top .you .arrow-right {
    top: 25% !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .my-team {
    margin-top: -20px !important;
    font-size: calc(0.390625vw * 10);
  }
  .mBody .second-body .Minfo .first-top .second-top .you .rank {
    margin: 0 5px 0 0 !important;
    width: 20px !important;
    height: 25px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .you .rank .my-rank {
    font-size: 15px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-team {
    font-size: calc(0.390625vw * 10);
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance {
    right: 35% !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-distance p {
    font-size: calc(10px) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-car {
    -webkit-transform: scale(0.8) rotateY(180deg) !important;
            transform: scale(0.8) rotateY(180deg) !important;
    padding: 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery {
    -webkit-transform: scale(0.8) !important;
            transform: scale(0.8) !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .next-battery .next-img-battery .next-battery-percent {
    margin: 0 0 0 5px !important;
    font-size: 12px !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .rank {
    margin: 0 25px 0 0 !important;
    width: 20px !important;
    height: 25px !important;
    margin: 0 10px 0 0 !important;
  }
  .mBody .second-body .Minfo .first-top .second-top .next-to-you .rank .next-rank {
    font-size: 15px !important;
  }
  .mBody .second-body .Minfo .first-top .qr {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 8 !important;
    grid-column: 1/9 !important;
    position: relative;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 !important;
            flex: 1 !important;
  }
  .mBody .second-body .Minfo .first-top .qr .main-qr-sms .sms-code {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
    margin-bottom: 20px !important;
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .Minfo .first-top .qr .more-info {
    position: absolute;
    top: 50%;
    right: 0%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .mBody .second-body .Minfo .first-top .qr .more-info p {
    padding: 0 20px 0 20px;
  }
  .mBody .second-body .Minfo .first-top .total-penalty p,
  .mBody .second-body .Minfo .first-top .total-penalty .total {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .mBody .second-body .Minfo .first-top .total-penalty .total {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .text {
    margin-left: 10px !important;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .penalty {
    margin-left: 10px !important;
    margin-top: -7px;
  }
  .mBody .second-body .Minfo .first-top .like-rank .rank-like,
  .mBody .second-body .Minfo .first-top .like-rank .votes {
    margin-bottom: 20px !important;
  }
  .mBody .second-body .motivation .motivation-info {
    font-size: calc(0.390625vw * 10) !important;
  }
  .mBody .second-body .motivation .info-motivation {
    font-size: 13px;
  }
  .mBody .second-body .Minfo .first-top .last-lap-penalty .penalty {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 500px) and (min-width: 400px) {
  .sms-code {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 400px) {
  .sms-code-title {
    margin-left: 0px !important;
  }
  .sms-code {
    margin-left: 0px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 364px) {
  .mBody header .details .full-name {
    margin-top: 20px;
  }
}
