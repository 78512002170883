@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {

    width: 80px;
    height: 80px;
    border: 15px solid #f3f3f3; /* Light grey */
    border-top: 15px solid #b0101c; /* Black */
    border-radius:50%;
    animation: spinner 1.5s linear infinite;
  }
  .spinner-container{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
  }
  